/* eslint-disable max-len */
export default {
    title: 'Monthly digest from the Acronis Blog',
    articlesTitle: 'Articles',
    articles: [
        {
            id: 1,
            image_id: 'f2497f8a81aa1470bbcdc8511729bd38',
            title: 'A refreshed cyber resilience plan can help your business in three significant ways',
            description: 'Refreshing your cyber resilience plan will improve your company’s posture with IT regulators, cybersecurity standards bodies and insurers. Le…',
            link: 'https://www.acronis.com/en-us/blog/posts/a-refreshed-cyber-resilience-plan-can-help-your-business-in-three-significant-ways/',
        },
        {
            id: 2,
            image_id: 'ccec5414a4b956da48bb6291bc7d81ed',
            title: 'Updated Acronis Cyber Protect delivers unlimited storage and more',
            description: 'The latest update to Acronis Cyber Protect delivers the features and functionality needed to not only protect and back up data but also ease the burd…',
            link: 'https://www.acronis.com/en-us/blog/posts/updated-acronis-cyber-protect-delivers-unlimited-storage-and-more/',
        },
        {
            id: 3,
            image_id: 'deb0f7759eba726af48cfe0c0a3c6ae6',
            title: 'Acronis takes security awareness training in a new direction',
            description: 'Acronis has launched Security Awareness Training that users won’t dread. The new offering is designed to be more fun, engaging and effective…',
            link: 'https://www.acronis.com/en-us/blog/posts/acronis-takes-security-awareness-training-in-a-new-direction/',
        },
        {
            id: 4,
            image_id: '5b00430880f5dc2dca284d91c907b3f6',
            title: 'What is business automation?',
            description: 'Learn what business automation is, its benefits, and how it streamlines operations to increase efficiency and productivity in your organization.',
            link: 'https://www.acronis.com/en-us/blog/posts/what-is-business-automation/',
        },
    ],
    insights: {
        title: 'Insights, trends, and analysis',
        buttonLink: 'https://www.acronis.com/en-us/cyber-protection-center/',
        buttonText: 'Find a story',
    },
    footerMenu: [
        {
            title: 'Customer Service',
            link: '#footer1',
        },
        {
            title: 'Send Feedback',
            link: '#footer2',
        },
        {
            title: 'Manage Subscriptions',
            link: '#footer3',
        },
        {
            title: 'Company Blog',
            link: '#footer4',
        },
    ],
    socialLinks: [
        {
            link: '#facebook',
            name: 'facebook',
        },
        {
            link: '#twitter',
            name: 'twitter',
        },
        {
            link: '#linkedin',
            name: 'linkedin',
        },
        {
            link: '#reddit',
            name: 'reddit',
        },
        {
            link: '#rss',
            name: 'rss',
        },
    ],
    addressText: `© ${new Date().getFullYear()} Acronis International GmbH.<br />
                Rheinweg 9, 8200 Schaffhausen, Switzerland.<br />
                © All rights reserved.`,
    noticeText: `Your information is used in accordance with our <a title="Acronis Privacy Statement">privacy statement</a>.
                You receive this email because you are subscribed for a blog newsletter.`,
};
