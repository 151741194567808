<template>
    <section class="home-page-new" :class="{ short: !hasSpotlight }">
        <div class="home-page-hero">
            <div class="home-page-hero__top">
                <div class="home-page-hero__bg">
                    <a-picture :background="hero.background" fit="cover" is-background />
                </div>
                <div class="home-page-hero__screenshot-wrap">
                    <div class="home-page-hero__screenshot-inner">
                        <a-picture class="home-page-hero__screenshot" :link="hero.screenshot" />
                    </div>
                </div>
                <div class="home-page-hero__left">
                    <h1 class="home-page-hero__title">
                        {{ hero.title }}
                    </h1>
                </div>
                <div class="home-page-hero__cols">
                    <div
                        class="home-page-hero__col home-page-hero__col_sp"
                        :class="{ 'home-page-hero__col_hover': activeSection === 'sp' }"
                        @mouseenter="!isMobile && sectionMouseEnter('sp')"
                        @mouseleave="!isMobile && sectionMouseLeave()"
                    >
                        <dynamic-link
                            v-if="!isMobile"
                            class="home-page-hero__col-outer-link"
                            :to="hero.serviceProviders.link.to"
                        />
                        <div class="home-page-hero__col-inner">
                            <div
                                class="home-page-hero__col-title"
                                @mouseenter="!isMobile && sectionLinkMouseEnter('sp')"
                                @click="isMobile && sectionClick('sp')"
                            >
                                <component
                                    :is="isMobile ? 'div' : 'dynamic-link'"
                                    class="home-page-hero__col-title-link"
                                    :to="hero.serviceProviders.link.to"
                                >
                                    {{ hero.serviceProviders.title }}&nbsp;<span class="home-page-hero__col-arrow">→</span>
                                </component>
                            </div>
                            <div class="home-page-hero__col-text">
                                {{ hero.serviceProviders.text }}
                            </div>
                            <s-home-page-new-cta
                                :content="contentCTA('serviceProviders')"
                                :active-section="activeSection"
                            />
                        </div>
                    </div>
                    <div
                        class="home-page-hero__col home-page-hero__col_biz"
                        :class="{ 'home-page-hero__col_hover': activeSection === 'biz' }"
                        @mouseenter="!isMobile && sectionMouseEnter('biz')"
                        @mouseleave="!isMobile && sectionMouseLeave()"
                    >
                        <dynamic-link v-if="!isMobile" class="home-page-hero__col-outer-link" :to="hero.businesses.link.to" />
                        <div class="home-page-hero__col-inner">
                            <div
                                class="home-page-hero__col-title"
                                @mouseenter="!isMobile && sectionLinkMouseEnter('biz')"
                                @click="isMobile && sectionClick('biz')"
                            >
                                <component
                                    :is="isMobile ? 'div' : 'dynamic-link'"
                                    class="home-page-hero__col-title-link"
                                    :to="hero.businesses.link.to"
                                >
                                    {{ hero.businesses.title }}&nbsp;<span class="home-page-hero__col-arrow">→</span>
                                </component>
                            </div>
                            <div class="home-page-hero__col-text">
                                {{ hero.businesses.text }}
                            </div>
                            <s-home-page-new-cta :content="contentCTA('businesses')" :active-section="activeSection" />
                        </div>
                    </div>
                    <div
                        class="home-page-hero__col home-page-hero__col_home"
                        :class="{ 'home-page-hero__col_hover': activeSection === 'home' }"
                        @mouseenter="!isMobile && sectionMouseEnter('home')"
                        @mouseleave="!isMobile && sectionMouseLeave()"
                    >
                        <dynamic-link
                            v-if="!isMobile"
                            class="home-page-hero__col-outer-link"
                            :to="hero.homeAndOffice.link.to"
                        />
                        <div class="home-page-hero__col-inner">
                            <div
                                class="home-page-hero__col-title"
                                @mouseenter="!isMobile && sectionLinkMouseEnter('home')"
                                @click="isMobile && sectionClick('home')"
                            >
                                <component
                                    :is="isMobile ? 'div' : 'dynamic-link'"
                                    class="home-page-hero__col-title-link"
                                    :to="hero.homeAndOffice.link.to"
                                >
                                    {{ hero.homeAndOffice.title }}&nbsp;<span class="home-page-hero__col-arrow">→</span>
                                </component>
                            </div>
                            <div class="home-page-hero__col-text">
                                {{ hero.homeAndOffice.text }}
                            </div>
                            <s-home-page-new-cta
                                :content="contentCTA('homeAndOffice')"
                                :active-section="activeSection"
                            />
                        </div>
                    </div>
                </div>
                <div class="home-page-hero__bg-gradient" />
            </div>
            <div ref="heroBottom" class="home-page-hero__bottom-anchor" />
            <div class="home-page-hero__bottom-wrap home-page-hero__bottom-wrap_no-hover">
                <div
                    class="home-page-hero__bottom home-page-hero__bottom_sp"
                    :class="{ 'home-page-hero__bottom_visible': activeSection === 'sp' }"
                    @mouseenter="!isMobile && sectionMouseEnter('sp')"
                    @mouseleave="!isMobile && sectionMouseLeave()"
                >
                    <div class="home-page-hero__bottom-close" @click="closeClick">
                        {{ hero.closeLabel }}
                        <a-glyph class="home-page-hero__close" name="close" />
                    </div>
                    <div class="home-page-hero__bottom-head">
                        <dynamic-link
                            v-if="hero.serviceProviders.link.to"
                            class="home-page-hero__bottom-head-title"
                            :to="hero.serviceProviders.link.to"
                        >
                            {{ hero.serviceProviders.title }}
                        </dynamic-link>
                        <div class="home-page-hero__bottom-head-text">
                            {{ hero.serviceProviders.text }}
                        </div>
                        <s-home-page-new-cta :content="contentCTA('serviceProviders')" :active-section="activeSection" />
                    </div>
                </div>
                <div
                    class="home-page-hero__bottom home-page-hero__bottom_biz"
                    :class="{ 'home-page-hero__bottom_visible': activeSection === 'biz' }"
                    @mouseenter="!isMobile && sectionMouseEnter('biz')"
                    @mouseleave="!isMobile && sectionMouseLeave()"
                >
                    <div class="home-page-hero__bottom-close" @click="closeClick">
                        {{ hero.closeLabel }}
                        <a-glyph class="home-page-hero__close" name="close" />
                    </div>
                    <div class="home-page-hero__bottom-head">
                        <dynamic-link
                            v-if="hero.businesses.link.to"
                            class="home-page-hero__bottom-head-title"
                            :to="hero.businesses.link.to"
                        >
                            {{ hero.businesses.title }}
                        </dynamic-link>
                        <div class="home-page-hero__bottom-head-text">
                            {{ hero.businesses.text }}
                        </div>
                        <s-home-page-new-cta :content="contentCTA('businesses')" :active-section="activeSection" />
                    </div>
                </div>
                <div
                    class="home-page-hero__bottom home-page-hero__bottom_home"
                    :class="{ 'home-page-hero__bottom_visible': activeSection === 'home' }"
                    @mouseenter="!isMobile && sectionMouseEnter('home')"
                    @mouseleave="!isMobile && sectionMouseLeave()"
                >
                    <div class="home-page-hero__bottom-close" @click="closeClick">
                        {{ hero.closeLabel }}
                        <a-glyph class="home-page-hero__close" name="close" />
                    </div>
                    <div class="home-page-hero__bottom-head">
                        <dynamic-link
                            v-if="hero.homeAndOffice.link.to"
                            class="home-page-hero__bottom-head-title"
                            :to="hero.homeAndOffice.link.to"
                        >
                            {{ hero.homeAndOffice.title }}
                        </dynamic-link>
                        <div class="home-page-hero__bottom-head-text">
                            {{ hero.homeAndOffice.text }}
                        </div>
                        <s-home-page-new-cta :content="contentCTA('homeAndOffice')" :active-section="activeSection" />
                    </div>
                </div>
                <div class="home-page-hero__bottom home-page-hero__bottom_static home-page-hero__bottom_visible">
                    <div class="home-page-hero__companies">
                        <h2 class="home-page-hero__companies-title">
                            {{ hero.companies.title }}
                        </h2>
                        <div class="home-page-hero__companies-logos">
                            <div v-for="(logo, i) in hero.companies.logos" :key="i" class="home-page-hero__companies-logo-wrap">
                                <a-picture class="home-page-hero__companies-logo" :link="logo" size="small" />
                            </div>
                        </div>
                    </div>
                    <div class="home-page-hero__learn">
                        <h2 class="home-page-hero__learn-title" v-html="hero.learnMore.title" />
                        <div class="home-page-hero__button-wrap">
                            <a-button
                                class="home-page-hero__learn-button"
                                v-bind="hero.learnMore.button"
                                :size="hero.learnMore.button?.size || 's'"
                                :type="hero.learnMore.button?.type || 'action'"
                            />
                        </div>
                        <div class="home-page-hero__learn-text" v-html="hero.learnMore.text" />
                        <a-picture
                            v-if="hero.learnMore.image"
                            class="home-page-hero__learn-bg"
                            :link="hero.learnMore.image"
                            :exact="true"
                        />
                        <a-picture
                            v-if="hero.learnMore.background"
                            class="home-page-hero__learn-bg"
                            :background="hero.learnMore.background"
                            fit="cover"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="home-page-new__integrations">
            <h2 class="home-page-new__integrations-title">
                <a-dangerous-html :content="integrations.title" />
            </h2>
            <div class="home-page-new__integrations-logos">
                <div v-for="(logo, i) in integrations.logos" :key="i" class="home-page-new__integrations-logo-wrap">
                    <a-picture class="home-page-new__integrations-logo" :link="logo" size="small" />
                </div>
            </div>
        </div>
        <div class="home-page-new__stats">
            <h2 class="home-page-new__stats-title">
                <a-dangerous-html :content="stats.title" />
            </h2>
            <div class="home-page-new__stats-items">
                <div v-for="(item, i) in stats.items" :key="i" class="home-page-new__stats-item">
                    <div class="home-page-new__stats-value">
                        {{ item.value }}
                    </div>
                    <div class="home-page-new__stats-text">
                        {{ item.text }}
                    </div>
                </div>
            </div>
        </div>
        <div class="home-page-new__logos">
            <div v-for="(logo, i) in logos" :key="i" class="home-page-new__logo-wrap">
                <a-picture class="home-page-new__logo" :link="logo" size="small" />
            </div>
        </div>
        <s-sport-partners
            class="home-page-new__sport"
            :class="{ short: !hasSpotlight }"
            :background="sport.background"
            :tag="sport.tag"
            :title="sport.title"
            :logos="sport.logos"
            :button="sport.button"
            :link="sport.link"
            modal-class-name=""
        />
        <div v-if="scs" class="home-page-new__scs">
            <h2 class="home-page-new__scs-title">
                <a-dangerous-html :content="scs.title" />
            </h2>
            <div class="home-page-new__integrations-logos">
                <div v-for="(logo, i) in scs.logos" :key="i" class="home-page-new__integrations-logo-wrap">
                    <a-picture class="home-page-new__integrations-logo" :link="logo" size="small" />
                </div>
            </div>
            <div class="home-page-new__scs-text">
                {{ scs.text }}
            </div>
            <a-link
                v-if="scs.link"
                class="home-page-new__scs-link"
                v-bind="scs.link"
                :text="scs.link.title"
                :glyph="scs.link.glyph || 'arrow'"
                :target="scs.link.target || '_blank'"
            />
        </div>
        <div v-if="hasSpotlight" class="home-page-new__spotlight">
            <div class="home-page-new__spotlight-inner">
                <div class="home-page-new__spotlight-events">
                    <a-slider class="event-slider" :options="sliderOptions">
                        <a-slide v-for="(eventCard, i) in eventCards" :key="i" class="event-slide">
                            <event-card class="home-page-new__spotlight-event" v-bind="eventCard" />
                        </a-slide>
                    </a-slider>
                </div>
                <div class="home-page-new__spotlight-left">
                    <h2 class="home-page-new__spotlight-title">
                        {{ spotlight.title }}
                    </h2>
                    <div class="home-page-new__spotlight-lead">
                        {{ spotlight.lead }}
                    </div>
                    <div v-if="blogPosts" class="home-page-new__spotlight-links">
                        <div class="home-page-new__spotlight-link-wrap">
                            <div class="home-page-new__spotlight-type">
                                {{ blogPosts.title }}
                            </div>
                            <a-link
                                v-for="item in blogPosts.items"
                                :key="item.id"
                                class="home-page-new__spotlight-link"
                                v-bind="item"
                                :to="`${blogLocale}${item.pathname || ''}`"
                                :text="item.title"
                                :size="item.size || 'paragraph'"
                                :target="item.target || '_blank'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import EventCard from '@core/slices/events/shared-components/event-card/event-card.vue';
import SSportPartners from '@core/slices/pages/sport-partners/sport-partners.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import SHomePageNewCta from './home-page-new-cta.vue';

export default {
    name: 'SHomePageNew',

    components: {
        AButton,
        AGlyph,
        ALink,
        ADangerousHtml,
        APicture,
        ASvgSprite,
        ASlider,
        ASlide,
        EventCard,
        SSportPartners,
        DynamicLink,
        SHomePageNewCta,
    },

    mixins: [breakpoint],

    props: {
        hero: {
            type: Object,
            required: true,
        },

        integrations: {
            type: Object,
            required: true,
        },

        stats: {
            type: Object,
            required: true,
        },

        logos: {
            type: Array,
            required: true,
        },

        sport: {
            type: Object,
            required: true,
        },

        spotlight: {
            type: Object,
            required: true,
        },

        scs: {
            type: Object,
            default: null,
        },

        experimentID: {
            type: String,
            default: '16033640128',
        },
    },

    data() {
        return {
            SCROLL_MARGIN: 200,
            SECTION_CLOSE_TIMEOUT: 100,
            sliderOptions: {
                showNavigation: true,
                showCounter: false,
                loop: false,
                slideToClickedSlide: false,
                spaceBetween: 0,
                slidesPerView: 'auto',
                resistanceRatio: 0,
                observer: true,
                observeParents: true,
                watchOverflow: true,
            },
            activeSection: null,
            mouseleaveTimeout: null,
        };
    },

    async serverPrefetch() {
        const locale = this.$route?.params?.locale || LOCALE_DEFAULT;

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 'events-interface-translations', LOCALE_DEFAULT }),
            this.$store.dispatch('eventsFeed/getFeed', { limit: 3, locale }),
            this.$store.dispatch('blog/getLatestBlogPosts', { limit: 2, locale }),
            this.$store.dispatch('publications/getFeed', { locale }),
        ]);
    },

    computed: {
        eventTranslations() {
            return this.$store.state.slices.items['events-interface-translations'];
        },

        eventCards() {
            if (!this.eventTranslations) return [];
            return this.$store.getters['eventsFeed/getEventCards'](this.spotlight, this.eventTranslations, this.locale);
        },

        blogLocale() {
            return this.$store.state.blog.latestBlogPosts?.locale;
        },

        blogPosts() {
            const items = this.$store.state.blog.latestBlogPosts?.items || [];
            if (!items.length) {
                return null;
            }
            return {
                title: this.spotlight.blogPostsTitle || 'Blog posts',
                items,
            };
        },

        hasSpotlight() {
            return this.spotlight && (this.blogPosts || this.eventCards.length);
        },

        locale() {
            return this.$route?.params?.locale || LOCALE_DEFAULT;
        },
    },

    mounted() {
        this.setDefaultSectionState();
    },

    methods: {
        sectionLinkMouseEnter(section) {
            this.activeSection = section;
            clearTimeout(this.mouseleaveTimeout);
            this.mouseleaveTimeout = null;
        },

        sectionMouseEnter(section) {
            if (this.activeSection === section) {
                clearTimeout(this.mouseleaveTimeout);
                this.mouseleaveTimeout = null;
            }
        },

        sectionMouseLeave() {
            clearTimeout(this.mouseleaveTimeout);
            this.mouseleaveTimeout = setTimeout(() => {
                this.setDefaultSectionState();
            }, this.SECTION_CLOSE_TIMEOUT);
        },

        // [WEB-40235] First section is opened by default
        setDefaultSectionState() {
            this.activeSection = 'sp';
        },

        sectionClick(section) {
            if (this.activeSection === section) {
                this.setDefaultSectionState();
            } else {
                this.activeSection = section;
            }

            window.scroll({
                top: this.$refs.heroBottom.offsetTop - this.SCROLL_MARGIN,
                behavior: 'smooth',
            });
        },

        closeClick() {
            this.activeSection = null;
        },

        contentCTA(productType) {
            return this.hero[productType].cta;
        },
    },
};
</script>

<style lang="postcss" scoped>
.home-page-new__spotlight-events :deep(.a-slider) {
    position: relative;
    overflow: visible;

    .event-slide {
        display: flex;
        flex-shrink: 0;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
        margin-inline-end: 16px;
        width: 85%;

        &:last-child {
            margin-inline-end: 0;
        }

        @media (--viewport-tablet) {
            width: 100%;
        }

        @media (--viewport-desktop) {
            width: 50%;
        }

        @media (--viewport-desktop-wide) {
            width: 40%;
        }

        @media (--viewport-tablet) {
            width: 100%;
        }

        @media (--viewport-desktop) {
            width: 50%;
        }

        @media (--viewport-desktop-wide) {
            width: 40%;
        }
    }

    .a-slide {
        opacity: 1;
    }

    .a-slider__controls {
        margin: 0 auto;

        @media (--viewport-tablet) {
            margin: 0 -12px;
        }
    }

    .a-slider__nav {
        margin-top: 24px;
        margin-bottom: 16px;
    }
}
</style>

<style lang="postcss">
.home-page-new {
    padding-top: 0;
    background: #fafafc;
    padding-bottom: 40px;
    &.short {
        padding-bottom: 0;
    }

    &__integrations {
        text-align: center;
        padding: 0 16px;
        max-width: 1440px;
        margin: 0 auto 56px;

        @media (--viewport-mobile-wide) {
            padding: 0 32px;
        }

        @media (--viewport-tablet) {
            padding: 0 32px;
            margin-bottom: 48px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 88px;
        }

        @media (--viewport-desktop-wide) {
            padding: 0 64px;
        }
    }

    &__integrations-title {
        @mixin display;

        font-weight: bold;
        margin: 0 auto 32px;
        color: var(--av-nav-primary);
        max-width: 555px;

        @media (--viewport-desktop) {
            margin-bottom: 48px;
            max-width: none;
        }

        a:hover {
            color: var(--av-brand-primary);
        }
    }

    &__integrations-logos {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 360px;

        @media (--viewport-tablet) {
            max-width: 540px;
        }

        @media (--viewport-desktop) {
            max-width: none;
            margin-bottom: -16px;
        }
    }

    &__integrations-logo-wrap {
        margin: 0 9.5px 8px;
        border: 1px solid var(--av-brand-light);
        box-sizing: border-box;
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
        border-radius: 8px;
        background: var(--av-inversed-primary);
        width: 96px;
        height: 96px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (--viewport-tablet) {
            padding: 0 12px;
            margin: 0 24px 8px;
            width: 120px;
            height: 120px;
        }

        @media (--viewport-desktop) {
            margin: 0 16px 24px;
        }
    }

    &__scs {
        text-align: center;
        padding: 0 16px;
        max-width: 1440px;
        margin: 0 auto 64px;

        @media (--viewport-tablet) {
            padding: 0 32px;
            margin-bottom: 72px;
        }

        @media (--viewport-desktop-wide) {
            padding: 0 64px;
        }
    }

    &__scs-title {
        @mixin display-strong;

        margin: 0 auto 40px;
        color: var(--av-nav-primary);
    }

    &__scs-text {
        @mixin paragraph;

        color: var(--av-fixed-secondary);
        margin: 32px auto 32px;

        @media (--viewport-desktop) {
            @mixin colls 10;
        }
    }

    &__scs-link {
        @mixin lead-accent;
    }

    &__stats {
        text-align: center;
        margin-bottom: 46px;
        color: var(--av-nav-primary);
    }

    &__stats-title {
        @mixin display;

        max-width: 584px;
        font-weight: bold;
        margin: 0 auto 48px;
        padding: 0 16px;

        @media (--viewport-mobile-wide) {
            padding: 0 32px;
        }

        @media (--viewport-desktop) {
            max-width: none;
        }

        a:hover {
            color: var(--av-brand-primary);
        }
    }

    &__stats-items {
        padding: 0 5px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 440px;

        @media (--viewport-tablet) {
            padding: 0 51px;
            max-width: 1280px;
        }

        @media (--viewport-desktop-wide) {
            padding: 0 88px;
        }
    }

    &__stats-item {
        margin-bottom: 24px;
        width: 50%;
        text-align: start;

        @media (--viewport-tablet) {
            width: auto;
        }
    }

    &__stats-value {
        @mixin display;

        font-weight: bold;
    }

    &__stats-text {
        @mixin title;
    }

    &__logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto 40px;
        padding: 0 12px;
        max-width: 440px;

        @media (--viewport-tablet) {
            padding: 0 8px;
            max-width: 1440px;
        }

        @media (--viewport-desktop) {
            padding: 0 48px;
            margin: 0 auto 64px;
        }

        @media (--viewport-desktop-wide) {
            padding: 0 97px;
        }
    }

    &__logo-wrap {
        margin: 0 4px 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (--viewport-tablet) {
            margin: 0 8px 24px;
        }
    }

    &__logo {
        .a-picture__img {
            max-width: 100px;
            max-height: 52px;

            @media (--viewport-tablet) {
                max-width: 108px;
                max-height: none;
            }

            @media (--viewport-desktop) {
                max-width: 130px;
                max-height: 72px;
            }
        }
    }

    &__sport {
        margin-bottom: 64px;

        @media (--viewport-tablet) {
            margin-bottom: 72px;
        }
        &.short {
            margin: 0;
        }
    }

    &__spotlight {
        width: 100%;
        overflow: hidden;

        @media (--viewport-tablet) {
            padding-top: 16px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 24px;
        }
    }

    &__spotlight-inner {
        display: flex;
        justify-content: center;
        padding: 0 16px;
        max-width: 1440px;
        margin: 0 auto;
        flex-direction: column-reverse;

        @media (--viewport-mobile-wide) {
            padding: 0 32px;
        }

        @media (--viewport-tablet) {
            flex-direction: row-reverse;
            padding: 0 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 0 64px;
        }
    }

    &__spotlight-left {
        position: relative;
        margin-inline-end: 16px;
        margin-bottom: 16px;
        z-index: 3;

        @media (--viewport-tablet) {
            @mixin colls 5;

            margin-bottom: 0;
            padding-inline-end: 16px;
        }

        @media (--viewport-desktop) {
            @mixin colls 4;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 3;
        }

        @media (--viewport-desktop-large) {
            padding-inline-end: 72px;
            @mixin colls 4;
        }
    }

    &__spotlight-title {
        @mixin display;

        font-weight: bold;
        margin-bottom: 24px;
        color: var(--av-nav-primary);
    }

    &__spotlight-lead {
        @mixin title;

        margin-bottom: 32px;
        color: var(--av-nav-primary);

        @media (--viewport-desktop-large) {
            margin-bottom: 64px;
        }
    }

    &__spotlight-type {
        margin-bottom: 8px;
    }

    &__spotlight-links {
        display: flex;
        flex-direction: column;
    }

    &__spotlight-link-wrap {
        margin-bottom: 24px;
    }

    &__spotlight-link-type {
        @mixin body;
    }

    &__spotlight-link {
        @mixin paragraph;

        text-decoration: none;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__spotlight-events {
        position: relative;

        @media (--viewport-tablet) {
            @mixin colls 7;
        }

        @media (--viewport-desktop) {
            @mixin colls 8;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 9;
        }

        @media (--viewport-desktop-large) {
            @mixin colls 8;
        }

        &::before {
            position: absolute;
            inset-inline-end: 100%;
            margin-inline-end: 80px;
            width: 100vw;
            height: 100%;
            content: '';
            display: block;
            background: #fafafc;
            top: -10px;
            z-index: 2;
        }

        &::after {
            position: absolute;
            top: -10px;
            inset-inline-end: 100%;
            margin-inline-end: 0;
            width: 80px;
            height: 100%;
            content: '';
            display: block;
            background: linear-gradient(
                90deg,
                rgba(250, 250, 252, 1) 85%,
                rgba(250, 250, 252, 0) 100%
            );
        }
        @media all and (min-width: 1790px) {
            .a-slider__controls {
                display: none;
            }
        }
    }

    &__spotlight-event {
        width: 100%;
        max-width: none;
    }
}
.home-page-hero {
    margin-bottom: 64px;
    color: var(--av-inversed-primary);

    @media (--viewport-tablet) {
        margin-bottom: 88px;
    }

    &__top {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        flex-direction: column;
        background: #0a1038;
        padding-bottom: 209px;
        overflow: hidden;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 90px;
            background: linear-gradient(0deg, rgba(11, 16, 48, 0) 5.36%, #0b1030 100%);
            z-index: 5;
        }

        @media (--viewport-tablet) {
            padding-bottom: 0;
            flex-direction: row;
            height: 462px;
        }

        @media (--viewport-desktop) {
            height: 462px;
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        height: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__bg-gradient {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-start: calc((100% - 1500px) / 2);
            width: 70px;
            height: 100%;
            background: linear-gradient(270deg, rgba(11, 16, 48, 0) 0%, #0a1038 100%);
            display: none;

            @media (--viewport-tablet) {
                display: block;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-end: calc((100% - 1500px) / 2);
            width: 70px;
            height: 100%;
            background: linear-gradient(90deg, rgba(11, 16, 48, 0) 0%, #0a1038 100%);
            display: none;

            @media (--viewport-tablet) {
                display: block;
            }
        }
    }

    &__left {
        position: relative;
        z-index: 2;
        padding: 102px 16px 0;

        @media (--viewport-mobile-wide) {
            max-width: 492px;
            padding: 134px 0 20px 32px;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 4 + (16px * 3) + 32px
            ); /* width of 4 cols + padding. TODO: rewrite with css grid */
            min-width: 230px;
        }

        @media (--viewport-desktop) {
            padding: 110px 0 20px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding-inline-start: 64px;
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 4 + (16px * 3) + 64px);
        }
    }

    &__title {
        @mixin display-accent;
        margin-bottom: 16px;

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-tablet) {
            margin-bottom: 81px;
        }

        @media (--viewport-desktop) {
            @mixin large-accent;
            margin-bottom: 50px;
        }
    }

    &__lead {
        @mixin lead;

        max-width: 400px;
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            padding-inline-end: 30px;
            margin-bottom: 0;
        }

        @media (--viewport-desktop) {
            padding-inline-end: 0;
        }

        @media (--viewport-desktop-wide) {
            @mixin title;
        }
    }

    &__cols {
        position: relative;
        max-width: 948px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        flex-grow: 0;
        z-index: 4;
        padding: 0 16px;

        .cta {
            display: none;
        }

        @media (--viewport-mobile-wide) {
            padding: 0 32px;
        }

        @media (--viewport-tablet) {
            padding: 0;
            flex-direction: row;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 8 + (16px * 8) + 32px
            ); /* width of 8 cols + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop-wide) {
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 8 + (16px * 8) + 64px);
        }
    }

    &__screenshot-wrap {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        bottom: 0;
        overflow: hidden;
        pointer-events: none;

        &::before {
            content: '';
            position: absolute;
            top: 238px;
            height: 2px;
            width: 2560px;
            inset-inline-start: 50%;
            transform: translate(-50%, 0);
            background: linear-gradient(270deg, #408bea 19.28%, rgba(64, 139, 234, 0) 67.46%);
            opacity: 0.8;
            z-index: 2;
            display: none;

            @media (--viewport-tablet) {
                display: block;
            }

            @media (--viewport-desktop) {
                top: 215px;
            }

            @media (--viewport-desktop-wide) {
                top: 230px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 135px;
            background: linear-gradient(0deg, #091140 0%, rgba(9, 17, 64, 0) 62.22%);
            opacity: 0.5;
            z-index: 3;
        }
    }

    &__screenshot-inner {
        position: relative;
        height: 100%;
        max-width: 1440px;

        @media (--viewport-tablet) {
            margin: 238px auto 0;
        }

        @media (--viewport-desktop) {
            margin: 215px auto 0;
        }

        @media (--viewport-desktop-wide) {
            margin: 230px auto 0;
        }
    }

    &__screenshot {
        position: absolute;
        inset-inline-start: 16px;
        inset-inline-end: 16px;
        top: 100%;
        margin-top: -173px;
        max-width: 750px;

        @media (--viewport-mobile-wide) {
            inset-inline-start: 32px;
            inset-inline-end: 32px;
        }

        @media (--viewport-tablet) {
            inset-inline-end: 32px;
            width: 41vw;
            inset-inline-start: auto;
            margin-top: 0;
        }

        @media (--viewport-desktop) {
            top: 24px;
        }

        @media (--viewport-desktop-wide) {
            .a-picture__img {
                max-width: 835px;
                max-height: none;
                height: auto;
                width: 100%;
            }
        }
        @media (--viewport-desktop-large) {
            inset-inline-start: 820px;
            max-width: 620px;
        }
    }

    &__col {
        position: relative;
        text-decoration: none;
        color: var(--av-inversed-primary);
        cursor: default;

        @media (--viewport-tablet) {
            width: calc((100% - 32px) / 3);
        }

        @media (--viewport-desktop-wide) {
            width: calc((100% - 64px) / 3);
        }

        &_hover {
            cursor: pointer;

            .home-page-hero {
                &__col-inner {
                    &::before {
                        transition: all 0.1s;
                        @media (--viewport-tablet) {
                            background: rgba(6, 56, 125, 0.8);
                            backdrop-filter: blur(10px);
                        }
                    }

                    &::after {
                        @media (--viewport-tablet) {
                            display: block;
                        }
                    }
                }

                &__col-title {
                    color: var(--av-inversed-primary);
                }

                &__col-text {
                    @media (--viewport-tablet) {
                        display: block;
                    }
                }

                &__col-arrow {
                    @media (--viewport-tablet) {
                        display: none;
                    }
                }
            }

            @media (--viewport-tablet) {
                .cta {
                    display: block;
                }
            }
        }

        &_home {
            position: relative;

            @media (--viewport-tablet) {
                width: calc(((100% - 32px) / 3) + 32px);
            }

            @media (--viewport-desktop-wide) {
                width: calc(((100% - 64px) / 3) + 64px);
            }

            .home-page-hero {
                &__col-inner {
                    &::before {
                        width: 100vw;
                    }

                    &::after {
                        width: 100vw;
                    }
                }
            }
        }
    }

    &__col-outer-link {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        bottom: 0;
    }

    &__col-inner {
        pointer-events: none;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (--viewport-tablet) {
            padding: 134px 0 40px;
            padding-inline-start: 16px;
        }

        @media (--viewport-desktop) {
            padding: 110px 0 66px;
            padding-inline-start: 24px;
        }

        @media (--viewport-desktop-wide) {
            padding: 110px 0 66px;
            padding-inline-start: 34px;
        }

        &::before {
            content: '';
            position: absolute;
            inset-inline-start: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
        }

        &::after {
            content: '';
            position: absolute;
            display: none;
            bottom: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 135px;
            background: linear-gradient(0deg, #091140 0%, rgba(9, 17, 64, 0) 62.22%);
            opacity: 0.5;
            z-index: 5;
            pointer-events: none;
        }
    }

    &__col-title {
        @mixin title-accent;

        margin-bottom: 32px;
        cursor: pointer;
        pointer-events: all;

        @media (--viewport-tablet) {
            @mixin lead-accent;

            max-width: 170px;
            margin-bottom: 16px;
        }

        @media (--viewport-desktop) {
            @mixin title-accent;

            margin-bottom: 32px;
        }
    }

    &__col-title-link {
        text-decoration: none;
        color: rgb(149, 196, 255);
    }

    &__col-glyph {
        fill: rgb(149, 196, 255);
    }

    &__col-text {
        @mixin descriptor;
        display: none;
        padding-inline-end: 18px;

        @media (--viewport-tablet) {
            overflow: hidden;
            max-height: 184px;
        }

        @media (--viewport-desktop) {
            @mixin body;
            max-height: 124px;
            padding-inline-end: 24px;
        }
    }

    &__bottom-wrap {
        position: relative;
        z-index: 3;

        @media (--viewport-tablet) {
            z-index: auto;
            min-height: 362px;
        }

        @media (--viewport-desktop) {
            min-height: 404px;
        }

        @media (--viewport-desktop-wide) {
            min-height: 414px;
        }

        &_no-hover {
            .home-page-hero {
                @media (--viewport-tablet) {
                    &__bottom {
                        &_sp,
                        &_biz,
                        &_home {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &__bottom {
        display: none;
        flex-wrap: nowrap;
        flex-direction: column;
        position: relative;
        z-index: 2;

        @media (--viewport-tablet) {
            flex-direction: row;
            justify-content: center;
            min-height: 100%;
        }

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            inset-inline-end: 0;
            width: 100vw;
            height: 100%;
            z-index: -1;
            background: rgb(7, 18, 85);

            @media (--viewport-tablet) {
                display: block;
            }
        }

        &_visible {
            display: flex;
        }

        &_default {
            overflow: hidden;

            &::before {
                background: #00296e;
            }

            &::after {
                content: '';
                display: none;
                position: absolute;
                top: 0;
                inset-inline-end: 0;
                height: 100%;
                width: 550px;
                background: linear-gradient(
                    291.78deg,
                    rgba(4, 9, 41, 0.8) 25.01%,
                    rgba(7, 18, 85, 0) 75.35%
                );

                @media (--viewport-tablet) {
                    inset-inline-end: -336px;
                    display: block;
                }

                @media (--viewport-desktop) {
                    inset-inline-end: -180px;
                }

                @media (--viewport-desktop-wide) {
                    inset-inline-end: -80px;
                }

                @media (--viewport-desktop-large) {
                    inset-inline-end: 0;
                    width: calc(550px + (100vw - 1440px) / 2);
                }
            }
        }

        &_home {
            @media (--viewport-tablet) {
                overflow: hidden;
            }

            &::after {
                content: '';
                display: none;
                position: absolute;
                top: 0;
                inset-inline-end: -120px;
                height: 100%;
                width: 550px;
                background: linear-gradient(
                    301.27deg,
                    rgba(4, 9, 41, 0.8) 6.99%,
                    rgba(7, 18, 85, 0) 59.4%
                );

                @media (--viewport-tablet) {
                    display: block;
                }

                @media (--viewport-desktop) {
                    inset-inline-end: -60px;
                }

                @media (--viewport-desktop-wide) {
                    inset-inline-end: -34px;
                }

                @media (--viewport-desktop-large) {
                    width: calc(550px + (100vw - 1440px) / 2);
                }
            }
        }

        &_sp,
        &_biz,
        &_home {
            margin: -200px 16px 16px;
            z-index: 3;

            @media (--viewport-mobile-wide) {
                margin: -200px 32px 32px;
            }

            @media (--viewport-tablet) {
                position: absolute;
                top: 0;
                inset-inline-start: 0;
                width: 100%;
                min-height: 100%;
                margin: 0;
            }
        }
    }

    &__bottom-close {
        @mixin paragraph-accent;

        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        background: var(--av-brand-primary);
        color: var(--av-inversed-primary);
        position: sticky;
        top: 0;
        z-index: 5;

        @media (--viewport-tablet) {
            display: none;
        }
    }

    &__bottom-head {
        display: block;
        padding: 16px 16px 24px;
        background: rgba(6, 56, 125, 1);

        @media (--viewport-tablet) {
            display: none;
        }
    }

    &__bottom-head-title {
        @mixin title-accent;

        margin-bottom: 16px;
    }

    &__bottom-head-text {
        @mixin paragraph;

        margin-bottom: 24px;
    }

    &__close {
        width: 12px;
        height: 12px;
        margin-inline-start: 10px;
        fill: var(--av-inversed-primary);
        position: relative;
        top: 1px;
    }

    &__companies {
        position: relative;
        background: var(--av-solid-brand-accent);
        padding: 40px 16px 40px;
        color: var(--av-nav-primary);
        flex-grow: 0;
        order: 2;

        @media (--viewport-mobile-wide) {
            padding: 40px 32px 40px;
        }

        @media (--viewport-tablet) {
            order: 1;
            max-width: 492px;
            padding: 32px 20px 32px 32px;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 4 + (16px * 3) + 32px
            ); /* width of 4 cols + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop) {
            padding: 40px 40px 48px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding-inline-start: 64px;
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 4 + (16px * 3) + 64px);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-end: 0;
            width: 100vw;
            height: 100%;
            background: var(--av-solid-brand-accent);
            z-index: -1;
            display: none;

            @media (--viewport-tablet) {
                display: block;
            }
        }
    }

    &__companies-title {
        @mixin paragraph;

        margin-bottom: 32px;
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;
            margin-bottom: 40px;
        }

        @media (--viewport-desktop) {
            @mixin lead;
        }

        @media (--viewport-desktop-wide) {
            @mixin title;
        }
    }

    &__learn {
        position: relative;
        max-width: 948px;
        background: rgb(7, 18, 85);
        padding: 32px 16px 40px;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        overflow: hidden;
        order: 1;

        @media (--viewport-mobile-wide) {
            padding: 32px 32px 40px;
        }

        @media (--viewport-tablet) {
            order: 2;
            padding: 32px 32px 34px 16px;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 8 + (16px * 8) + 32px
            ); /* width of 8 cols + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop) {
            padding: 40px 40px 40px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 40px 40px 40px 38px;
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 8 + (16px * 8) + 64px);
        }
    }

    &__learn-title {
        @mixin title;
        font-weight: bold;
        margin-bottom: 24px;
        position: relative;
        z-index: 3;

        @media (--viewport-tablet) {
            @mixin lead;
            max-width: 57%;
            margin-bottom: 32px;
        }

        @media (--viewport-desktop) {
            @mixin title;
            max-width: 318px;
            margin-bottom: 16px;
        }

        @media (--viewport-desktop-wide) {
            @mixin display;
            max-width: 480px;
            margin-bottom: 24px;
        }
    }

    &__learn-text {
        @mixin paragraph;

        margin-bottom: 24px;
        position: relative;
        z-index: 3;

        @media (--viewport-tablet) {
            max-width: 57%;
            margin-bottom: 16px;
        }

        @media (--viewport-desktop) {
            max-width: 318px;
            @mixin lead;
        }

        @media (--viewport-desktop-wide) {
            max-width: 480px;
        }
    }

    &__button-wrap {
        text-align: center;
        margin-bottom: 48px;

        @media (--viewport-tablet) {
            text-align: start;
        }
    }

    &__numbers,
    &__customers,
    &__awards {
        position: relative;
        background: #0058b2;
        color: var(--av-inversed-primary);
        flex-grow: 0;

        @media (--viewport-tablet) {
            max-width: 493px;
            padding: 40px 74px 40px 32px;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 4 + (16px * 3) + 32px
            ); /* width of 4 cols + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop-wide) {
            padding-inline-start: 64px;
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 4 + (16px * 3) + 64px);
        }

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            inset-inline-end: 0;
            width: 100vw;
            height: 100%;
            background: #0058b2;
            z-index: -1;

            @media (--viewport-tablet) {
                display: block;
            }
        }
    }

    &__numbers {
        padding: 40px 16px 31px;
        order: 3;

        @media (--viewport-tablet) {
            order: 1;
            padding: 32px 17px 36px 32px;
        }

        @media (--viewport-desktop) {
            padding: 40px 20px 40px 32px;
        }

        @media (--viewport-desktop-large) {
            padding: 40px 60px 40px 64px;
        }
    }

    &__customers {
        padding: 40px 16px 32px;
        display: flex;
        flex-direction: column;
        order: 2;

        @media (--viewport-tablet) {
            order: 1;
            padding: 32px 17px 40px 32px;
        }

        @media (--viewport-desktop) {
            padding: 40px 52px 40px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 40px 24px 40px 32px;
        }

        @media (--viewport-desktop-large) {
            padding: 40px 74px 40px 64px;
        }
    }

    &__numbers-title {
        @mixin title;

        margin-bottom: 32px;

        @media (--viewport-tablet) {
            @mixin paragraph;

            margin-bottom: 40px;
        }

        @media (--viewport-desktop) {
            @mixin lead;
        }

        @media (--viewport-desktop-wide) {
            @mixin title;
        }
    }

    &__numbers-items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: row;

        @media (--viewport-tablet) {
            flex-direction: column;
        }

        @media (--viewport-desktop) {
            flex-direction: row;
        }
    }

    &__numbers-item {
        margin-bottom: 25px;
        max-width: 50%;
        padding-inline-end: 10px;

        @media (--viewport-tablet) {
            margin-bottom: 20px;
            padding-inline-end: 0;
            max-width: none;
        }

        @media (--viewport-desktop) {
            margin-bottom: 32px;
            padding-inline-end: 20px;
        }

        @media (--viewport-desktop-wide) {
            margin-bottom: 16px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__numbers-value {
        font-size: 24px;
        line-height: 32px;
        font-weight: bold;

        @media (--viewport-desktop) {
            font-size: 24px;
            line-height: 48px;
        }

        @media (--viewport-desktop-wide) {
            font-size: 32px;
        }
    }

    &__numbers-text {
        @mixin lead;

        br {
            display: none;
        }

        @media (--viewport-desktop) {
            @mixin paragraph;

            max-width: 135px;

            br {
                display: inline;
            }
        }

        @media (--viewport-desktop-wide) {
            @mixin lead;

            max-width: none;
        }
    }

    &__sp-learn {
        background: var(--av-nav-primary);
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        order: 1;
        padding: 40px 16px 40px;

        @media (--viewport-tablet) {
            order: 2;
            max-width: 458px;
            padding: 32px 16px 44px 16px;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 4 + (16px * 5)
            ); /* width of 4 cols + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop) {
            padding: 40px 30px 48px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 40px 30px 48px 38px;
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 4 + (16px * 5));
        }

        .home-page-hero {
            &__learn-title,
            &__learn-text {
                max-width: none;
            }
        }

        &_bg2 {
            background: #00296e;
        }
    }

    &__learn-link {
        @mixin title-accent;

        position: relative;
        text-decoration: none;
        z-index: 2;
        color: #72b0ff;

        &:hover {
            color: var(--av-inversed-primary);
        }

        @media (--viewport-tablet) {
            @mixin paragraph-accent;
        }

        @media (--viewport-desktop) {
            @mixin lead-accent;
        }
    }

    &__sp-video {
        position: relative;
        color: var(--av-inversed-primary);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 0;
        order: 2;
        height: 367px;
        text-align: center;
        padding: 24px 28px 40px;

        @media (--viewport-tablet) {
            text-align: start;
            height: auto;
            order: 3;
            max-width: 491px;
            padding: 24px 24px 44px;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 4 + (16px * 3) + 32px
            ); /* width of 4 cols + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop) {
            padding: 24px 24px 48px;
        }

        @media (--viewport-desktop-wide) {
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 4 + (16px * 3) + 64px);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #041b3a 15.22%, rgba(0, 32, 77, 0) 60.39%);

            /* TODO: fix a malformed rule
            linset-inline-starteft: 0; */
        }
    }

    &__sp-video-text {
        @mixin descriptor;
        position: relative;
        z-index: 2;
        margin-bottom: 8px;

        @media (--viewport-tablet) {
            max-width: 290px;
        }
    }

    &__sp-video-link {
        @mixin title-accent;
        position: relative;
        text-decoration: none;
        z-index: 2;
        color: #72b0ff;
        &:hover {
            color: var(--av-brand-primary);
        }
        @media (--viewport-tablet) {
            @mixin paragraph-accent;
        }
        @media (--viewport-desktop) {
            @mixin lead-accent;
        }
    }

    &__customers-title {
        @mixin title;
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            @mixin paragraph;
            margin-bottom: 32px;
        }

        @media (--viewport-desktop-wide) {
            @mixin title;
            margin-bottom: 21px;
        }

        @media (--viewport-desktop-large) {
            margin-bottom: 40px;
        }
    }

    &__customers-content {
        @mixin paragraph;
        position: relative;
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            @mixin descriptor;
        }

        @media (--viewport-desktop-wide) {
            @mixin paragraph;
        }
    }

    &__customers-quotes {
        position: absolute;
        top: -8px;
        inset-inline-start: -2px;
        width: 160px;
        opacity: 0.4;
        fill: var(--av-nav-primary);
    }

    &__customers-text {
        position: relative;
    }

    &__customers-logo {
        margin: 0 auto 24px;
        max-width: 166px;

        @media (--viewport-tablet) {
            max-width: 132px;
            margin: 0 0 24px;
        }

        @media (--viewport-desktop) {
            max-width: 166px;
        }
    }

    &__customers-link {
        @mixin title-accent;
        margin-top: auto;
        text-align: center;
        color: var(--av-inversed-primary);

        &:hover {
            color: var(--av-inversed-light);
        }

        @media (--viewport-tablet) {
            @mixin paragraph-accent;
            text-align: start;
        }

        @media (--viewport-desktop-wide) {
            @mixin lead-accent;
        }
    }

    &__biz-learn {
        position: relative;
        max-width: 949px;
        background: rgba(6, 56, 125, 1);
        display: flex;
        flex-direction: column;
        color: var(--av-inversed-primary);
        padding: 40px 16px 40px;
        flex-grow: 0;
        order: 1;

        @media (--viewport-tablet) {
            order: 2;
            padding: 32px 0 14px 16px;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 8 + (16px * 8) + 32px
            ); /* width of 8 cols + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop) {
            padding: 40px 40px 40px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 40px 40px 40px 38px;
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 8 + (16px * 8) + 64px);
        }
    }

    &__biz-learn-title {
        @mixin title;

        font-weight: bold;
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            @mixin lead;

            margin-bottom: 40px;
        }

        @media (--viewport-desktop) {
            @mixin title;

            margin-bottom: 24px;
        }

        @media (--viewport-desktop-wide) {
            @mixin display;

            margin-bottom: 32px;
        }
    }

    &__biz-learn-items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;

        @media (--viewport-tablet) {
            flex-direction: row;
        }
    }

    &__biz-learn-item {
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            padding-inline-end: 20px;
            margin-bottom: 40px;
            width: calc(100% / 3);
        }
    }

    &__biz-learn-item-title {
        @mixin lead-accent;
        margin-bottom: 8px;

        @media (--viewport-tablet) {
            @mixin paragraph;
        }

        @media (--viewport-desktop-wide) {
            @mixin title;
        }
    }

    &__biz-learn-item-text {
        @mixin descriptor;
        @media (--viewport-desktop-wide) {
            font-size: 16px;
        }
    }

    &__biz-button-wrap {
        padding-top: 32px;
        text-align: center;

        @media (--viewport-tablet) {
            padding-top: 0;
            padding-bottom: 40px;
            text-align: start;
            width: calc(100% / 3);
            display: flex;
            align-items: flex-end;
        }
    }

    &__awards {
        order: 2;
        padding: 40px 16px 40px;

        @media (--viewport-tablet) {
            order: 1;
            padding: 32px 34px 40px 32px;
        }

        @media (--viewport-desktop) {
            padding: 40px 34px 40px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 40px 74px 40px 64px;
        }
    }

    &__awards-title {
        @mixin title;

        margin-bottom: 32px;

        @media (--viewport-tablet) {
            @mixin paragraph;

            margin-bottom: 30px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 40px;
        }

        @media (--viewport-desktop-wide) {
            @mixin title;
        }
    }

    &__home-learn {
        position: relative;
        max-width: 949px;
        background: rgba(6, 56, 125, 1);
        display: flex;
        flex-direction: column;
        color: var(--av-inversed-primary);
        flex-grow: 0;
        order: 1;
        padding: 40px 16px 40px;

        @media (--viewport-tablet) {
            order: 2;
            padding: 32px 40px 44px 16px;
            width: calc(
                ((100vw - (32px * 2) - (16px * 11)) / 12) * 8 + (16px * 8) + 32px
            ); /* width of 8 cols + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop) {
            padding: 40px 40px 48px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 40px 40px 48px 38px;
            width: calc(((100vw - (64px * 2) - (16px * 11)) / 12) * 8 + (16px * 8) + 64px);
        }
    }

    &__home-learn-title {
        @mixin title;

        font-weight: bold;
        margin-bottom: 24px;
        position: relative;
        z-index: 3;

        @media (--viewport-tablet) {
            @mixin lead;

            max-width: 224px;
            margin-bottom: 16px;
        }

        @media (--viewport-desktop) {
            @mixin title;

            max-width: 318px;
        }

        @media (--viewport-desktop-wide) {
            @mixin display;

            max-width: 392px;
            margin-bottom: 32px;
        }
    }

    &__home-learn-text {
        @mixin paragraph;

        margin-bottom: 40px;
        position: relative;
        z-index: 3;

        @media (--viewport-tablet) {
            margin-bottom: 16px;
            max-width: 224px;
        }

        @media (--viewport-desktop) {
            max-width: 318px;
        }

        @media (--viewport-desktop-wide) {
            @mixin lead;

            max-width: 392px;
        }
    }

    &__companies-logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @media (--viewport-tablet) {
            max-width: 290px;
            margin-bottom: -32px;
        }
    }

    &__companies-logo-wrap {
        width: 50%;
        height: 49px;
        padding: 0 15px;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (--viewport-tablet) {
            padding: 0;
            padding-inline-end: 10px;
        }

        @media (--viewport-desktop) {
            padding-inline-end: 20px;
            margin-bottom: 24px;
        }
    }

    &__companies-logo {
        .a-picture__img {
            max-height: 50px;

            @media (--viewport-tablet) {
                max-height: none;
            }
        }
    }

    &__learn-bg {
        position: relative;
        margin: 0 auto 0;
        z-index: 2;

        @media (--viewport-tablet) {
            position: absolute;
            inset-inline-end: -130px;
            top: 0;
            margin: 0;
            bottom: 0;
        }

        @media (--viewport-desktop) {
            inset-inline-end: -90px;
        }

        @media (--viewport-desktop-wide) {
            inset-inline-end: 0;
        }

        .a-picture__img {
            max-width: 260px;

            @media (--viewport-tablet) {
                max-width: none;
            }
            @media (--viewport-desktop-wide) {
                max-height: 411px;
            }
        }
    }

    &__sp-video-logo {
        position: relative;
        z-index: 2;
        max-width: 166px;
        margin: 0 auto 8px;

        @media (--viewport-tablet) {
            margin: auto 0 8px;
        }
    }

    &__sp-video-preview {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
    }

    &__awards-logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @media (--viewport-tablet) {
            max-width: 290px;
            margin-bottom: -32px;
        }
    }

    &__awards-logo-wrap {
        width: 50%;
        height: 49px;
        padding: 0 15px;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (--viewport-tablet) {
            padding: 0;
            padding-inline-end: 10px;
        }

        @media (--viewport-desktop) {
            padding-inline-end: 20px;
            margin-bottom: 24px;
        }
    }

    &__awards-logo {
        .a-picture__img {
            max-height: 50px;

            @media (--viewport-tablet) {
                max-height: none;
            }
        }
    }

    &__home-learn-bg {
        position: relative;
        inset-inline-start: 50%;
        transform: translate(-50%, 0);
        margin-bottom: 48px;
        max-width: 238px;
        margin-inline-start: 50px;

        @media (--viewport-tablet) {
            margin-inline-start: 0;
            max-width: none;
            position: absolute;
            transform: none;
            top: 70px;
            margin-bottom: 0;
            inset-inline-start: calc(
                (2 * (100% - 32px) / 3) + 16px
            ); /* width of 2 out of 3 nav elements + padding. TODO: rewrite with css grid */
        }

        @media (--viewport-desktop) {
            top: 48px;
            inset-inline-start: calc((2 * (100% - 32px) / 3) + 24px);
        }

        @media (--viewport-desktop-wide) {
            top: 32px;
            inset-inline-start: calc((2 * (100% - 64px) / 3) + 34px);
        }

        .a-picture__img {
            max-width: 238px;

            @media (--viewport-tablet) {
                max-width: none;
                max-height: 212px;
                transform: translate(-3%, 0);
            }

            @media (--viewport-desktop) {
                max-height: 302px;
            }

            @media (--viewport-desktop-wide) {
                max-height: 350px;
            }
        }
    }
}

.home-page-new + .s-subscription + .s-global-footer,
.home-page-new + .s-global-footer {
    .a-social__icon {
        fill: var(--av-nav-primary);
    }
}

[dir='rtl'] {
    .home-page-new__spotlight-events::after {
        background: linear-gradient(
            -90deg,
            rgba(250, 250, 252, 1) 85%,
            rgba(250, 250, 252, 0) 100%
        );
    }
    .home-page-hero__col-title {
        max-width: 160px;
    }
    .home-page-hero__col-arrow {
        display: inline-block;
        transform: rotate(180deg);
        position: relative;
        vertical-align: middle;
    }
    .home-page-hero__col_hover .home-page-hero__col-arrow {
        @media (--viewport-tablet) {
            display: none;
        }
    }
}
</style>
