import { AxiosError } from 'axios';
import { pick, omit } from 'lodash';
// eslint-disable-next-line import/no-named-default -- default named import conflicts with Webpack
import { default as Pino } from 'pino';
import * as std from 'pino-std-serializers';

const customErrorSerializer = (err: Error) => {
    if (err.constructor === AxiosError) {
        const ax = err as AxiosError;

        return {
            ...omit(ax, 'config', 'request'),
            config: pick(ax.config, 'baseURL', 'url'),
        };
    }

    return std.err(err);
};

const getLogLevel = (): string => {
    // Can't use env.ts here because it's an isomorphic script
    if (typeof process === 'undefined') return 'info';
    return process.env.HEAD_SITE_MAIN_SERVER_LOG_LEVEL || 'info';
};

const options: Pino.LoggerOptions = {
    level: getLogLevel(),
    serializers: {
        req: std.req,
        res: std.res,
        e: customErrorSerializer,
        err: customErrorSerializer,
        error: customErrorSerializer,
    },
    browser: {
        asObject: false,
    },
};

export const Logger = Pino(options);
