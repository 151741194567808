<template>
    <div v-if="content" class="s-form-success" :class="{ 's-form-success--small-paddings': hasExtraContent }">
        <a-glyph v-if="content.glyph" fill="fixed-success" :name="content.glyph" />
        <p v-if="content.header" class="header" v-html="content.header" />
        <p v-if="content.paragraph" class="paragraph" v-html="content.paragraph" />
        <a-picture v-if="content.image" class="image" :link="content.image" />
        <p v-if="content.title && !content.header" class="title">
            {{ content.title }}
        </p>
        <p v-if="content.description" class="description" v-html="content.description" />

        <a-link
            v-if="content.backToLink"
            v-bind="content.backToLink"
            :class="content.backToLink.className"
            @click="$emit('backToForm', content.backToLink.backTo)"
        />

        <template v-if="content.button">
            <a-button
                v-if="content.button.to"
                class="cta-button"
                :class="content.button.className"
                v-bind="content.button"
                :type="content.button.type || 'action'"
                :text="content.button.text || 'Download'"
                :event="content.button.event || { doNotSendGA: true }"
            />
            <a-button
                v-else
                class="cta-button"
                :class="content.button.className"
                v-bind="content.button"
                :type="content.button.type || 'action'"
                :text="content.button.text || 'Download'"
                :event="content.button.event || { doNotSendGA: true }"
                @click="$emit('onClickButtonSuccess')"
            />
        </template>

        <div v-if="content.links?.length" class="links">
            <dynamic-link
                v-for="(link, i) in content.links"
                :key="i"
                class="link"
                v-bind="link"
            >
                {{ link.text }}
            </dynamic-link>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import form from '@core/mixins/form.js';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';

export default {
    name: 'FormSuccess',

    components: {
        DynamicLink,
        AButton,
        AGlyph,
        ALink,
        APicture,
    },

    mixins: [form],

    props: {
        userData: {
            type: Object,
            default: null,
        },
        form: {
            type: Object,
            default: () => ({
                image: '/images/partner-form/success',
                description: 'Your submission has been sent',
                title: 'Thank you',
            }),
        },
    },

    emits: ['onClickButtonSuccess', 'backToForm'],

    data: () => ({
        content: {},
    }),

    computed: {
        hasExtraContent() {
            return Boolean(this.content.header && this.content.links);
        },
    },
    beforeMount() {
        const variation = this.form?.variation?.field;
        const value = this.userData?.[variation];
        const hasVariation = Boolean(value && this.form?.variation?.value?.[value]);
        this.content = hasVariation ? this.form.variation.value[value] : this.form;
        this.content.eventTracking = this.form.eventTracking || false;
    },
    async mounted() {
        if (this.content.isPromoSubscription) {
            const companyType = this.userData.company_type || '';
            const product = {
                'Corporate End-Customer': 'Business news and products',
                'Prosumer End-Customer': 'Home and Office news and products',
                'Service Provider': 'Service Provider news and products',
            };

            this.sendDataLayer({
                eventLabel: `news letter subscription: ${product[companyType] || ''}`,
                eventAction: 'submit_form_success',
                eventCategory: 'form',
                formTimer: formHelper.getSecondsOnPage(), // WEB-44946
                emailAddress: await commonUtils.toSHA256String(this.userData.email), // WEB-45304
            });
            return;
        }

        if (this.content.eventTracking) {
            this.sendDataLayer({
                eventAction: window.location.href,
                ...this.content.eventTracking,
                formTimer: formHelper.getSecondsOnPage(), // WEB-44946
                emailAddress: await commonUtils.toSHA256String(this.userData.email), // WEB-45304
            });
        }
    },
};
</script>

<style lang="postcss" scoped>
.s-form-success {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    padding: 89px 24px 90px;
    color: var(--av-nav-primary);
    background: var(--av-inversed-primary);
    border: 1px solid var(--av-fixed-lightest);
    box-shadow: 0 4px 8px rgba(36, 49, 67, 0.2);

    @media (--viewport-desktop-large) {
        padding: 113px 24px 114px;
    }
    @media (--viewport-desktop-wide) {
        padding: 105px 24px 106px;
    }

    .title {
        @mixin title;
        font-weight: 500;
        margin: 32px 0 0;
        color: var(--av-nav-primary);
    }

    .image {
        height: 250px;
        margin: 24px 0 auto;

        @media (--viewport-desktop) {
            height: 252px;
        }
        @media (--viewport-desktop-wide) {
            height: 250px;
        }

        &:deep(.a-picture__img) {
            height: 100%;
        }
    }

    .link,
    .paragraph,
    .description {
        @mixin paragraph;
        margin: 24px 0 0;
        max-width: 472px;
    }

    .header {
        @mixin title-accent;
        max-width: 472px;
    }

    &:deep(a) {
        text-decoration: none;
        color: var(--av-brand-secondary);
    }

    .a-button {
        color: var(--av-inversed-primary);
    }

    .links {
        display: flex;
        flex-direction: column;
    }

    .cta-button {
        margin-top: 24px;
    }

    &--small-paddings {
        padding: 16px 40px 40px;

        .header {
            margin-bottom: 24px;
        }

        .cta-button.full-width {
            width: 100%;
        }
    }

    .extra-button {
        margin: 16px 0 0;

        &.a-button_type_inverse {
            border-radius: 4px;
            border: 1px solid var(--av-brand-primary);

            &:hover {
                background: var(--av-brand-accent);
            }

            &:active {
                background: var(--el-secondary-active);
            }

            &:deep(.a-button__content) {
                color: var(--av-brand-primary);
                @mixin paragraph-accent;
            }
        }
    }

    .back-to-form-link {
        margin-top: 16px;

        &:deep(.a-link__content) {
            @mixin paragraph;
            color: var(--av-brand-primary);
        }
    }
}
</style>
