<template>
    <div :class="['s-purchasing-acp-products-simple', { 'has-border': settings.page?.hasBorder }]">
        <div :class="['wrapper', { 'has-background': settings.page?.hasBackground }]">
            <div v-if="page.title || page.description" class="header-container">
                <div class="items items-container header">
                    <div class="item-1">
                        <p v-if="page.title" class="header-title" v-html="page.title" />
                        <p v-if="page.description" class="header-description" v-html="page.description" />
                    </div>
                </div>
            </div>
            <div class="a-container">
                <div class="items">
                    <div class="item-1 license">
                        <div class="workloads">
                            <p v-if="page.label?.text" class="license-label">
                                <a-label v-bind="{...page.label, size: 'l', text: ''}">
                                    {{ page.label.text }}
                                </a-label>
                            </p>
                            <p v-if="page.subtitle" class="license-title" v-html="page.subtitle" />
                            <p v-if="promoDescription" class="promo-description">
                                <a-glyph v-if="promo?.glyph" :name="promo?.glyph" size="s" /> {{ promoDescription }}
                            </p>
                            <div class="workloads-wrapper">
                                <div class="workload-item workload-item-1">
                                    <p v-if="page.workload?.lead" class="workload-item-lead">
                                        {{ page.workload.lead }}<a-tooltip v-if="page.workload.tooltip" v-bind="page.workload.tooltip" />
                                    </p>
                                    <div v-for="(item, workloadIndex) in workloadList" :key="`workload-${workloadIndex}`" class="workload">
                                        <ANumberInput
                                            :step="item.multiplier || 1"
                                            :min="(item.quantityMin * item.multiplier) || 0"
                                            :max="(item.quantityMax * item.multiplier) || 99"
                                            @change="changeQuantityWorkload(item, $event)"
                                        />
                                        <div class="workload-title">
                                            {{ currentWorkload(item.workload).title }}<a-tooltip
                                                v-if="currentWorkload(item.workload).tooltip"
                                                glyph="info-circle"
                                                :text="currentWorkload(item.workload).tooltip"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="page.checkboxes?.length" class="workload-item workload-item-2">
                                    <div
                                        v-for="(item, checkboxesIndex) in page.checkboxes"
                                        :key="`checkbox-${checkboxesIndex}`"
                                        :class="{'checkbox-title': item.checkbox, 'workload-item-lead': item.lead}"
                                    >
                                        <p v-if="item.checkbox" :key="`checkbox-title-${checkboxesIndex}`">
                                            <el-checkbox v-model="checkboxes[checkboxesIndex]" @change="onChangeCheckbox()">
                                                {{ item.checkbox }}
                                            </el-checkbox><a-tooltip v-if="item.tooltip" v-bind="item.tooltip" />
                                        </p>
                                        <p v-else-if="item.lead">
                                            {{ item.lead }}<a-tooltip v-if="item.tooltip" v-bind="item.tooltip" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cloud">
                            <div class="cloud-item">
                                <s-dropdown
                                    :description="page.dropdown?.cloud"
                                    :disabled="!isCloudDropdownVisible || hasMicrosoft365ID || hasGoogleWorkspaceID"
                                    :placeholder="cloudPlaceholder"
                                    :tooltip="page.tooltip?.cloud"
                                    :list="optionList(cloudStorage, { type: 'cloud' })"
                                    @changeItem="changeCloud($event)"
                                />
                            </div>
                            <div class="cloud-item">
                                <s-dropdown
                                    :description="page.dropdown?.period"
                                    :tooltip="page.tooltip?.period"
                                    :list="listCloudPeriod"
                                    @changeItem="changePeriod($event)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="item-2 summary">
                        <div class="summary-title" v-html="page.summary?.title" />
                        <div v-if="!addedLicenses.length" class="summary-notification">
                            <p v-if="page.summary?.message?.text" class="summary-message">
                                <a-glyph v-if="page.summary.message?.glyph" :name="page.summary.message.glyph" size="s" />
                                {{ page.summary.message.text }}
                            </p>
                            <p v-if="page.summary?.description" v-html="page.summary.description" />
                        </div>

                        <div v-else class="summary-licenses">
                            <div
                                v-for="(license, licenseIndex) in addedLicenses"
                                :key="`license-${licenseIndex}`"
                                class="license-item"
                            >
                                <div class="license-info">
                                    <p class="license-name" v-html="licenseInfo(license)" />
                                    <p v-html="titlePeriod(license.period, 'long')" />
                                    <p v-if="license.cloud" v-html="titleCloud(license.cloud)" />
                                    <p v-if="cloudStorageIncluded(license)" v-html="cloudStorageIncluded(license)" />
                                </div>
                                <div :class="['license-price', {'license-price-promo': hasDiscount(license)}]">
                                    <div class="license-price-container">
                                        <div v-if="hasDiscount(license)" class="license-price-full" v-html="licensePriceFull(license)" />
                                        <div class="license-price-current" v-html="licensePrice(license)" />
                                    </div>
                                    <a-button v-bind="buttonRemove" @click="handleClickRemove(license)" />
                                </div>
                            </div>
                        </div>

                        <s-warnings
                            class="summary-warnings"
                            :list="warnings"
                            @addCloud="changeCloud({ cloud: 250 })"
                            @changeQuantity="$emit('changeQuantity', $event)"
                            @cartRecommendation="$emit('cartRecommendation', $event)"
                        />

                        <div v-if="hasPromoNotification" class="summary-promo-notification">
                            <a-glyph name="info-circle-o" />
                            <p v-html="promo.notification" />
                        </div>

                        <s-total class="summary-total" :settings="total" :style="{ 'margin-top': addedLicenses.length ? 'auto' : 0 }" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="page.footer" class="footer-container">
            <div class="items items-container footer a-container">
                <div class="item-1" v-html="page.footer.title" />
                <div v-if="page.footer.link" class="item-2">
                    <a-link
                        v-bind="page.footer.link"
                        :event="{context: 'open expert mode'}"
                        :size="page.footer.link?.size || 'paragraph'"
                        :glyph="page.footer.link?.glyph || 'burger-menu'"
                        :glyph-position="page.footer.link?.glyphPosition || 'left'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import analytics from '@core/mixins/analytics.js';
import currency from '@core/mixins/currency.js';
import SDropdown from './components/dropdown.vue';
import ANumberInput from './components/num-picker.vue';
import STotal from './components/total.vue';
import SWarnings from './components/warnings.vue';
import mixin from './mixin.js';

export default {
    name: 'SProducts',

    components: {
        AButton,
        AGlyph,
        ALabel,
        ALink,
        ATooltip,
        SDropdown,
        ANumberInput,
        STotal,
        SWarnings,
        ElCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
    },

    mixins: [mixin, analytics, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['changePeriod', 'changeQuantity', 'changeAddon', 'cartRecommendation'],

    data: () => ({
        cloud: 0,
        period: 1,
        checkboxes: [],
        isButtonDisabled: true,
        buttonRemove: {
            size: 's',
            glyph: 'times',
            event: { doNotSendGA: true },
        },
    }),

    computed: {
        promo() {
            if (this.settings?.promo?.isPromoActive) return this.settings.promo;
            if (this.page?.summary?.message?.isPromoActive) return this.page.summary.message;
            return {};
        },

        isCloudDropdownVisible() {
            return this.addedLicenses.length;
        },

        promoDescription() {
            if (!this.promo.isPromoActive) return '';
            return this.promo.description || this.page?.summary?.message?.text || '';
        },

        hasPromoNotification() {
            return this.addedLicenses.length && this.promo.isPromoActive && this.promo.notification;
        },

        total() {
            return {
                ...this.settings,
                button: {
                    size: 's',
                    type: 'action',
                    glyph: 'arrow',
                    event: { doNotSendGA: true },
                    text: this.translation('Proceed to checkout'),
                },
                title: this.translation('Total'),
            };
        },

        page() {
            return this.settings.dictionary?.simple || {};
        },

        workloadList() {
            return this.settings.workloads
                .map((workload) => this.settings.licenses.find((license) => {
                    if (license.workload !== workload.name) return false;
                    if (this.period !== license.period) return false;
                    if (['windows-server-essentials'].includes(license.workload)) {
                        return license.product === 'acps';
                    }
                    if (['google-workspace', 'microsoft-365'].includes(license.workload)) {
                        return license.product === 'acpba';
                    }
                    if (['workstation', 'server', 'virtual-host', 'public-cloud-virtual-machine'].includes(license.workload)) {
                        const comparison = (item) => item.workload === license.workload && item.product === 'acpa';
                        const isAdded = this.addedLicenses.find(comparison)?.quantity;
                        if (license.product === 'acpa' && isAdded) return true;
                        if (license.product === 'acps' && !isAdded) return true;
                    }
                    return false;
                }))
                .filter(Boolean);
        },

        cloudPlaceholder() {
            return !this.cloud ? this.translation('Select') : this.optionPlaceholder(this.cloudStorage, { type: 'cloud' });
        },

        cloudStorage() {
            return this.settings.products.find((item) => item.product === 'cloud-storage') || {};
        },

        listCloudPeriod() {
            let price1Y = 0;
            const list = this.optionList(this.cloudStorage, { type: 'period' });
            return list.map((item) => {
                const price = this.listCloudPeriodPrice(item.period, item.cloud);
                const result = {
                    ...item,
                    priceFull: '',
                    selected: item.period === this.period,
                    price: this.addedLicenses.length ? this.setCurrency(price) : '',
                };
                if (!this.addedLicenses.length) return result;
                if (item.period === 1) {
                    price1Y = price;
                    return result;
                }
                const percent = Math.round(100 - (100 * price) / (price1Y * item.period));
                return {
                    ...result,
                    promo: this.translation('Save @percent').replace('@percent', `${percent}%`),
                };
            });
        },
        advancedLicense() {
            return ['workstation', 'server', 'public-cloud-virtual-machine', 'virtual-host'];
        },
    },

    mounted() {
        this.checkboxes = this.page.checkboxes?.map(() => false) || [];
    },

    methods: {
        licenseInfo(license) {
            if (license.workload) {
                const title = this.currentWorkload(license.workload).title;
                const quantity = license.quantity;
                return `${title} ×&nbsp;${quantity}`;
            }

            return this.settings.products.find((item) => item.product === license.product).title;
        },

        listCloudPeriodPrice(period, cloud) {
            return this.addedLicenses
                .map((item) => {
                    const result = this.settings.licenses
                        .filter((i) => i.workload === item.workload && i.product === item.product && i.period === period)
                        .find((i) => (i.cloud ? i.cloud === cloud : i));
                    return result.store.price * item.quantity;
                })
                .reduce((a, b) => a + b, 0);
        },

        hasDiscount(license) {
            return this.promo.isPromoActive && license.store.discount_absolute;
        },

        licensePrice(license) {
            return this.setCurrency(license.store.price * license.quantity);
        },

        licensePriceFull(license) {
            const price = license.store.price + license.store.discount_absolute;
            return this.setCurrency(price * license.quantity);
        },

        licenseChange(item, quantity, product) {
            const period = item.period;
            const workload = item.workload;
            const comparison = (i) => i.workload === workload && i.product === product && i.period === period;
            const license = this.settings.licenses.find(comparison);
            this.sendEcommerceAdd({ ...license, quantity });
            this.$emit('changeQuantity', { ...license, quantity, isChecked: true, setPeriod: this.period });
        },
        onChangeCheckbox() {
            const comparison = (item) => item.quantity && this.advancedLicense.includes(item.workload);
            const workloadList = this.workloadList.filter(comparison) || [];
            workloadList.forEach((license) => this.changeQuantityWorkload(license, license.quantity));
        },

        changeQuantityWorkload(license, event) {
            const multiplier = license.multiplier || 1;
            let quantity = event / multiplier;
            if (this.advancedLicense.includes(license.workload)) {
                const hasAcronis = this.checkboxes.find((item) => Boolean(item));
                const isVirtualHost = ['virtual-host'].includes(license.workload);
                const quantityConstraint = isVirtualHost ? 3 : 10;
                if (license.product === 'acps' && (quantity >= quantityConstraint || hasAcronis)) {
                    this.licenseChange(license, quantity, 'acpa');
                    quantity = 0;
                }
                if (license.product === 'acpa' && quantity < quantityConstraint && !hasAcronis) {
                    this.licenseChange(license, quantity, 'acps');
                    quantity = 0;
                }
            }
            if (quantity === 0) this.checkCartRecommendation(license);
            if (quantity === 1 && quantity > license.quantity) this.sendEcommerceAdd({ ...license, quantity: 1 });
            this.$emit('changeQuantity', { ...license, quantity, isChecked: Boolean(quantity), setPeriod: this.period });
        },

        changeCloud(item) {
            const cloud = this.settings.licenses.find((i) => i.cloud === item.cloud && i.period === this.period);
            if (this.cloud) this.sendEcommerceRemove({ ...this.cloud, quantity: 1 });
            this.sendEcommerceAdd({ ...cloud, quantity: 1 });
            this.$emit('changeAddon', { ...cloud, type: 'cloud', setPeriod: this.period });
            this.cloud = cloud;
        },

        changePeriod(item) {
            if (this.period === item.period) return;
            this.period = item.period;
            this.addedLicenses.forEach((license) => {
                if (license.workload) {
                    this.sendEcommerceRemove({ ...license, period: this.period });
                    this.$emit('changePeriod', { ...license, period: item.period });
                }
                if (this.cloud && license.product === 'cloud-storage') {
                    this.changeCloud({ cloud: item.cloud });
                }
            });
        },

        handleClickRemove(license) {
            this.checkCartRecommendation(license);
            if (license.product === 'cloud-storage') {
                this.cloud = 0;
                this.$emit('changeAddon', { ...license, quantity: 0 });
                return;
            }
            this.$emit('changeQuantity', { ...license, quantity: 0, setPeriod: this.period });
        },

        checkCartRecommendation(license) {
            const payload = { ...license, quantity: 1 };
            if (this.settings.cartRecommendations.includes(license.cleverBridgeCartID)) {
                this.$emit('cartRecommendation', { remove: license });
                payload.item_list_name = 'cart recommendation';
            }
            this.sendEcommerceRemove(payload);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-products-simple {
    &.has-border {
        border-top: 1px solid var(--av-brand-light);
        border-bottom: 1px solid var(--av-brand-light);
    }
    .wrapper {
        position: relative;
        padding: 64px 0 32px;
        &.has-background {
            &~ .footer-container {
                border-top: 1px solid var(--av-brand-light);
            }
            &:before {
                @media (--viewport-desktop) {
                    top: 0;
                    z-index: 1;
                    content: '';
                    height: 100%;
                    position: absolute;
                    width: calc(50% + 164px);
                    background: var(--av-fixed-info-accent);
                    border-inline-end: 1px solid var(--av-brand-light);
                }
                @media (--viewport-desktop-wide) {
                    width: calc(50% + 198px);
                }
                @media (--viewport-desktop-large) {
                    width: calc(50% + 278px);
                }
            }
        }
        .a-container {
            z-index: 1;
        }
    }
    .header-container {
        z-index: 1;
        position: relative;
        .header {
            padding: 0 16px 32px;
            @media (--viewport-tablet) {
                padding: 0 var(--container-paddings-mobile-wide) 32px;
            }
            @media (--viewport-desktop-wide) {
                padding: 0 var(--container-paddings-desktop-wide) 32px;
            }
            @media (--viewport-desktop-large) {
                padding: 0 0 32px;
            }
            .item-1 {
                @media (--viewport-desktop) {
                    max-width: 66%;
                }
                @media (--viewport-desktop-large) {
                    max-width: 70%;
                }
            }
        }
        .header-title {
            @mixin display-accent;
            color: var(--av-nav-primary);
        }
        .header-description {
            @mixin paragraph;
            color: var(--av-nav-primary);
        }
    }
    .items {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        @media (--viewport-desktop) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        .item-2 {
            width: 100%;
            @media (--viewport-tablet) {
                margin: 0 auto;
                max-width: 574px;
            }
            @media (--viewport-desktop) {
                width: 309px;
                min-width: 309px;
                display: flex;
                flex-direction: column;
            }
            @media (--viewport-desktop-wide) {
                min-width: 372px;
            }
        }
    }
    .items-container {
        @media (--viewport-mobile-wide) {
            padding-inline-start: var(--container-paddings-mobile-wide);
            padding-inline-end: var(--container-paddings-mobile-wide);
        }
        @media (--viewport-desktop-wide) {
            padding-inline-start: var(--container-paddings-desktop-wide);
            padding-inline-end: var(--container-paddings-desktop-wide);
        }
        @media (--viewport-desktop-large) {
            margin: 0 auto;
            max-width: 1312px;
            padding-inline-start: var(--container-paddings-desktop-large);
            padding-inline-end: var(--container-paddings-desktop-large);
        }
    }
    .license {
        gap: 32px;
        display: flex;
        flex-wrap: wrap;
        padding: 32px 0;
        border-radius: 8px;
        align-content: space-between;
        background: var(--av-inversed-primary);
        border: 2px solid var(--av-fixed-info-light);
        box-shadow: 0 4px 8px 0 var(--av-fixed-invisible);
        .license-label {
            top: -12px;
            max-width: 290px;
            position: absolute;
            @media (--viewport-tablet) {
                max-width: 490px;
            }
            .a-label {
                overflow: hidden;
                max-height: 24px;
                text-align: center;
            }
        }
        .license-title {
            @mixin title;
            text-align: center;
            color: var(--av-nav-primary);
            @media (--viewport-tablet) {
                text-align: left;
            }
        }
        .workloads-wrapper {
            gap: 24px;
            display: flex;
            margin-top: 32px;
            flex-direction: column;
            justify-content: space-between;
            @media (--viewport-tablet) {
                gap: unset;
                flex-direction: row;
            }
            .workload-item {
                padding-top: 24px;
                @media (--viewport-tablet) {
                    padding: 24px;
                    &:first-child {
                        border-radius: 8px;
                        background: rgba(64, 139, 234, 0.05);
                    }
                }
                .workload-item-lead {
                    @mixin body-accent;
                    color: var(--av-fixed-primary);
                    &:deep(.a-glyph) {
                        fill: #949aa3;
                    }
                }
                .checkbox-title {
                    display: flex;
                    height: 32px;
                    align-items: center;
                    white-space: nowrap;
                }
            }
            .workload-item-2 {
                @media (--viewport-tablet) {
                    width: 242px;
                    min-width: 242px;
                }
                .workload-item-lead {
                    margin-bottom: 16px;
                    &:not(:first-child) {
                        margin-top: 24px;
                    }
                }
            }
        }
        .promo-description {
            @mixin lead-accent;
            gap: 8px;
            display: flex;
            flex-wrap: nowrap;
            text-align: center;
            align-items: center;
            flex-direction: column;
            color: var(--av-button-action);
            @media (--viewport-tablet) {
                flex-direction: row;
            }
            .a-glyph {
                fill: var(--av-button-action);
            }
        }
        .workloads {
            width: 100%;
            padding: 0 16px;
            @media (--viewport-tablet) {
                padding: 0 24px;
            }
        }
        .workload {
            display: flex;
            margin-top: 24px;
            align-items: center;
            &:first-child {
                margin-top: 0;
            }
            .workload-title {
                @mixin paragraph;
                color: var(--av-fixed-primary);
                padding-inline-start: 8px;
                @media (--viewport-tablet) {
                    padding-inline-start: 16px;
                }
            }
        }
        .cloud {
            gap: 24px;
            display: flex;
            width: 100%;
            padding: 24px 16px 0;
            flex-direction: column;
            border-top: 1px solid var(--av-brand-light);
            @media (--viewport-tablet) {
                padding: 24px 40px 0;
                flex-direction: row;
            }
            &:deep(.description) {
                @mixin paragraph-accent;
                margin-bottom: 16px;
            }
        }
        .cloud-item {
            &:not(:first-child) {
                &:deep(.label-item) {
                    .label-text-promo {
                        min-width: 100%;
                    }
                }
            }
            @media (--viewport-tablet) {
                width: 50%;
            }
            @media (--viewport-desktop-wide) {
                width: 282px;
            }
        }
        &:deep(.a-tooltip ) {
            .a-glyph {
                margin-bottom: 2px;
                vertical-align: middle;
                margin-inline-start: 4px;
                fill: var(--av-brand-primary);
            }
        }
    }
    .summary {
        padding: 24px 16px;
        border-radius: 8px;
        background: var(--av-inversed-primary);
        border: 2px solid var(--av-fixed-info-light);
        box-shadow: 0 4px 8px 0 var(--av-fixed-invisible);
        @media (--viewport-tablet) {
            padding: 24px 16px;
        }
        .summary-title {
            @mixin nav-title-accent;
            color: var(--av-nav-primary);
        }
        .summary-licenses {
            padding: 24px 8px 0;
            .license-item {
                gap: 16px;
                display: flex;
                justify-content: space-between;
                &:not(:first-child) {
                    margin-top: 8px;
                    padding-top: 16px;
                    border-top: 1px solid var(--av-brand-secondary-light);
                }
                &:last-child {
                    padding-bottom: 8px;
                    border-bottom: 1px solid var(--av-brand-secondary-light);
                }
            }
            .license-name {
                @mixin body-accent;
                color: var(--av-fixed-secondary);
            }
            .license-info {
                @mixin caption;
                color: var(--av-fixed-light);
            }
            .license-price {
                display: flex;
                text-align: end;
                flex-wrap: nowrap;
                align-items: flex-start;
                justify-content: flex-end;
                .a-button {
                    padding: 0;
                    border: none;
                    margin: 4px 0 0;
                    box-shadow: none;
                    margin-inline-start: 4px;
                    &:hover {
                        background: transparent;
                    }
                    &:deep(.a-glyph) {
                        margin: 0;
                        fill: var(--av-fixed-info-light);
                    }
                }
            }
            .license-price-promo {
                .a-button {
                    margin-top: 0;
                    margin-inline-start: 4px;
                }
            }
            .license-price-full {
                @mixin caption;
                color: var(--av-fixed-light);
                text-decoration: line-through;
            }
            .license-price-current {
                @mixin body-accent;
                color: var(--av-fixed-secondary);
            }
        }
        .summary-notification {
            @mixin lead-accent;
            margin: auto 0;
            text-align: center;
            color: var(--av-nav-primary);
            display: flex;
            flex-wrap: wrap;
            min-height: 300px;
            align-items: center;
            justify-content: center;
        }
        .summary-message {
            @mixin lead-accent;
            display: flex;
            align-items: center;
            flex-direction: column;
            color: var(--av-button-action);
            .a-glyph {
                fill: var(--av-button-action);
            }
        }
        .summary-warnings {
            margin-top: 24px;
            &:deep(.warning-type-promo) {
                border-color: var(--av-brand-secondary-light);
                background-color: var(--av-brand-secondary-accent);
                &:last-child {
                    margin-bottom: 8px;
                }
                .a-glyph {
                    fill: var(--av-brand-primary);
                }
            }
        }
        .summary-total {
            padding-top: 16px;
            &:deep(.wrapper) {
                display: flex;
                justify-content: space-between;
                .title {
                    @mixin paragraph-accent;
                    color: var(--av-nav-primary);
                }
                .price {
                    align-items: flex-end;
                    flex-direction: column;
                }
                .price-full {
                    @mixin caption;
                    color: var(--av-fixed-light);
                }
                .price-current {
                    @mixin paragraph-accent;
                }
                .price-vat {
                    @mixin caption;
                    color: var(--av-fixed-light);
                }
            }
            &:deep(.info) {
                text-align: end;
                justify-content: flex-end;
            }
            &:deep(.a-button) {
                flex-direction: row;
                justify-content: space-between;
            }
            &:deep(.a-glyph) {
                margin: 0;
            }
        }
        .summary-promo-notification {
            @mixin caption-accent;
            display: flex;
            padding: 8px;
            margin-top: 8px;
            border-radius: 8px;
            align-items: flex-start;
            color: var(--av-fixed-success-dark);
            border: 1px solid var(--av-fixed-success);
            background: var(--av-fixed-success-accent);
            .a-glyph {
                min-width: 16px;
                margin-inline-end: 8px;
                fill: var(--av-fixed-success-dark);
            }
        }
    }
    .footer-container {
        background: var(--av-brand-secondary-accent);
        .footer {
            padding: 16px;
            text-align: center;
            align-items: center;
            justify-content: center;
            @media (--viewport-tablet) {
                max-width: 508px;
                padding: 4px 32px;
            }
            @media (--viewport-desktop) {
                max-width: unset;
            }
            @media (--viewport-desktop-wide) {
                padding: 16px var(--container-paddings-desktop-wide);
            }
            @media (--viewport-desktop-large) {
                margin: 0 auto;
                padding: 16px 0;
                max-width: 1312px;
            }
            .item-1 {
                @mixin lead-accent;
                color: var(--av-brand-primary);
                @media (--viewport-desktop) {
                    width: 100%;
                    text-align: left;
                }
            }
            &:deep(.a-link__content) {
                @mixin note-heading;
                padding: 4px 8px;
                border-radius: 12px;
                padding-inline-start: 28px;
                border: 1px solid var(--av-fixed-info);
                background: var(--av-fixed-info-accent);
            }
            &:deep(.a-glyph) {
                top: 0;
                margin: 4px 0 0;
                margin-inline-start: 8px;
            }
        }
    }
}
</style>
