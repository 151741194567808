import { render, staticRenderFns } from "./price-calculator.vue?vue&type=template&id=51fdf9ac&scoped=true"
import script from "./price-calculator.vue?vue&type=script&lang=js"
export * from "./price-calculator.vue?vue&type=script&lang=js"
import style0 from "./price-calculator.vue?vue&type=style&index=0&id=51fdf9ac&prod&lang=postcss"
import style1 from "./price-calculator.vue?vue&type=style&index=1&id=51fdf9ac&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51fdf9ac",
  null
  
)

export default component.exports