import { render, staticRenderFns } from "./blog-post-body.vue?vue&type=template&id=1f824fde&scoped=true"
import script from "./blog-post-body.vue?vue&type=script&lang=js"
export * from "./blog-post-body.vue?vue&type=script&lang=js"
import style0 from "./blog-post-body.vue?vue&type=style&index=0&id=1f824fde&prod&lang=postcss&scoped=true"
import style1 from "./blog-post-body.vue?vue&type=style&index=1&id=1f824fde&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f824fde",
  null
  
)

export default component.exports