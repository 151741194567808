import SchemaHandler from '@model/seo/schemaHandler';
import { ArticleSchema, QuestionSchema, SchemaConfig } from '@model/seo/schemas';
import { ACRONIS_OG_IMAGE_ID, getOgImage } from '@utils/html-meta';
import {
    getAudienceSchema,
    getBCP47Locale,
    getSiteUrl,
    getPostVideoObject,
    getUrlWithLocale,
    MAIN_SITE_URL,
    ROUTE_NAME_RC_ARTICLE,
    ROUTE_NAME_PR_ARTICLE,
} from '../helper';
import VideoObjectHandler from './videoObjectHandler';

const AUTHOR_ID_ACRONIS = 4;
const AUTHOR_NAME_ACRONIS = 'Acronis';

export default class ArticleHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: ArticleSchema): Promise<ArticleSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: ArticleSchema): ArticleSchema {
        const locale = this.ctx.$route?.params?.locale || 'en-us';
        const siteUrl = getSiteUrl(this.ctx);
        const fullPath = `${siteUrl}${this.ctx.$route?.path || '/'}`;
        const routeName = this.ctx.$route.name;

        if (routeName === ROUTE_NAME_RC_ARTICLE) {
            return this.getRcArticleData(staticConfig, locale, fullPath);
        }

        if (routeName === ROUTE_NAME_PR_ARTICLE) {
            return this.getPrArticleData(staticConfig, locale, fullPath);
        }

        return this.getBlogPostData(staticConfig, locale, fullPath);
    }

    getRcArticleData(staticConfig: ArticleSchema, locale: string, fullPath: string) {
        const dataSource = this.ctx.$store.state.resourceCenter.resource.items?.[0];
        if (!dataSource) return null;

        const name = dataSource.seo_title || 'Acronis';
        const image = getOgImage(`@${dataSource.cover_image_id}`, process.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);

        let articleBody: any;

        try {
            articleBody = JSON.parse(dataSource.description);
            articleBody = articleBody?.blocks || [];
        } catch (error) {
            articleBody = [];
        }

        let video: any = getPostVideoObject(dataSource.description);
        if (!video && dataSource.url?.includes('youtube')) {
            video = { data: { id: dataSource.url.split('/').pop() } };
        }

        const faqItems = this.getFAQItems(articleBody, fullPath);

        const data: ArticleSchema = {
            ...staticConfig,
            '@id': `${fullPath}#Article`,
            url: fullPath,
            publisher: { '@id': `${MAIN_SITE_URL}/#Organization` },
            inLanguage: getBCP47Locale(locale),
            headline: name,
            mainEntityOfPage: fullPath,
            datePublished: dataSource.published_at,
            image: [image, ...this.getImages(articleBody)],
            audience: getAudienceSchema(fullPath, dataSource.audiences?.[0]?.seo_title),
            articleBody: this.parsePostBody(articleBody),
            author: {
                '@id': `${MAIN_SITE_URL}/#${AUTHOR_NAME_ACRONIS}`,
                name: AUTHOR_NAME_ACRONIS,
                url: `${getUrlWithLocale(this.ctx)}/blog/authors/${AUTHOR_NAME_ACRONIS}/`,
            },
            name,
        };

        if (faqItems?.length) {
            data.hasPart = {
                '@type': 'FAQPage',
                '@id': `${fullPath}#Article_hasPart_FAQPage`,
                mainEntity: faqItems,
            };
        }

        if (video) {
            data.video = this.getVideoObjectSchema(dataSource, video);
        }

        return data;
    }

    getPrArticleData(staticConfig: ArticleSchema, locale: string, fullPath: string) {
        const dataSource = this.ctx.$store.state.pressReleases.pressRelease.items?.[0];
        if (!dataSource) return null;

        const author = this.ctx.$store.state.pressReleases.pressReleaseAuthor.items?.[0];
        const name = dataSource.seo_title || 'Acronis press release';
        const image = getOgImage(`@${ACRONIS_OG_IMAGE_ID}`, process.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);

        let articleBody: any;

        try {
            articleBody = JSON.parse(dataSource.contents);
            articleBody = articleBody?.blocks || [];
        } catch (error) {
            articleBody = [];
        }

        const video = getPostVideoObject(dataSource.contents);
        const faqItems = this.getFAQItems(articleBody, fullPath);

        const data: ArticleSchema = {
            ...staticConfig,
            '@id': `${fullPath}#Article`,
            url: fullPath,
            publisher: { '@id': `${MAIN_SITE_URL}/#Organization` },
            inLanguage: getBCP47Locale(locale),
            headline: name,
            mainEntityOfPage: fullPath,
            datePublished: dataSource.published_at,
            image: [image, ...this.getImages(articleBody)],
            articleBody: this.parsePostBody(articleBody),
            author: {
                '@id': `${MAIN_SITE_URL}/#${author?.name || AUTHOR_NAME_ACRONIS}`,
                name: author?.name || AUTHOR_NAME_ACRONIS,
            },
            name,
        };

        if (faqItems?.length) {
            data.hasPart = {
                '@type': 'FAQPage',
                '@id': `${fullPath}#Article_hasPart_FAQPage`,
                mainEntity: faqItems,
            };
        }

        if (video) {
            data.video = this.getVideoObjectSchema(dataSource, video);
        }

        return data;
    }

    getBlogPostData(staticConfig: ArticleSchema, locale: string, fullPath: string) {
        const post = this.ctx.$store.state.blog.post.items[0];
        if (!post) return null;

        const page = this.ctx.$store.state.pages?.page;
        const name = page?.meta?.title || 'Acronis';
        const image = getOgImage(`@${post.image_id}`, process.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);

        let postBody: any;

        try {
            postBody = JSON.parse(post.body);
            postBody = postBody?.blocks || [];
        } catch (error) {
            postBody = [];
        }

        const video = getPostVideoObject(post.body);
        const faqItems = this.getFAQItems(postBody, fullPath);

        let authorName = post.author.id === AUTHOR_ID_ACRONIS ? AUTHOR_NAME_ACRONIS : post.author.name?.replaceAll(' ', '');
        if (!authorName) authorName = AUTHOR_NAME_ACRONIS;
        const data: ArticleSchema = {
            ...staticConfig,
            '@id': `${fullPath}#Article`,
            url: fullPath,
            publisher: { '@id': `${MAIN_SITE_URL}/#Organization` },
            inLanguage: getBCP47Locale(locale),
            headline: post.seo_title,
            mainEntityOfPage: fullPath,
            datePublished: post.published_at,
            image: [image, ...this.getImages(postBody)],
            articleBody: this.parsePostBody(postBody),
            author: {
                '@id': `${MAIN_SITE_URL}/#${authorName}`,
                name: authorName,
                url: `${getUrlWithLocale(this.ctx)}/blog/authors/${post.author.slug}/`,
            },
            name,
        };

        if (faqItems?.length) {
            data.hasPart = {
                '@type': 'FAQPage',
                '@id': `${fullPath}#Article_hasPart_FAQPage`,
                mainEntity: faqItems,
            };
        }

        if (video) {
            data.video = this.getVideoObjectSchema(post, video);
        }

        return data;
    }

    parsePostBody(body: any): string {
        const result = body.map((item) => {
            switch (item.type) {
                case 'header':
                    return this.getHeaderMarkup(item.data);
                case 'paragraph':
                    return this.getParagraphMarkup(item.data);
                case 'list':
                    return this.getListMarkup(item.data);
                default:
                    return '';
            }
        });
        return result.join('');
    }

    getHeaderMarkup(data: any): string {
        const heading = `h${data.level || 2}`;
        return `<${heading}><span>${data.text}</span></${heading}>`;
    }

    getParagraphMarkup(data: any): string {
        return `<p>${data.text}</p>`;
    }

    getListMarkup(data: any): string {
        const tag = data.style === 'unordered' ? 'ul' : 'ol';
        const list = data.items.map((item) => `<li>${item}</li>`).join('');
        return `<${tag}>${list}</${tag}>`;
    }

    getVideoObjectSchema(post: any, video: any) {
        const handler = new VideoObjectHandler(this.ctx, this.schemasConfig);
        return handler.getVideoObjectSchema(post, video);
    }

    getImages(postBody: any) {
        const imageItems = postBody.filter((item: { type: string; }) => item.type === 'image');

        if (!imageItems.length) return [];

        return imageItems.map((image: any) => {
            const imageUrl = image.data?.file?.url;
            const baseUrl = process.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;
            return getOgImage(`@${imageUrl}`, baseUrl);
        });
    }

    getFAQItems(postBody: any, url: string): QuestionSchema[] {
        const faqItems = [];
        let index = 0;

        while (index < postBody.length) {
            const availableHeaderLevels = [2, 3];
            const item = postBody[index];
            const isQuestionHeader =
                item.type === 'header' &&
                availableHeaderLevels.includes(item.data.level) &&
                item.data?.text?.includes('?');

            if (!isQuestionHeader) {
                index++;
                // eslint-disable-next-line no-continue
                continue;
            }

            let answerChunks: string[] = [];

            const atLevel = item.data.level;
            let nextIndex = index + 1;

            while (
                nextIndex < postBody.length &&
                    (postBody[nextIndex].type !== 'header' || postBody[nextIndex].data?.level > atLevel)
            ) {
                const nextData = postBody[nextIndex].data;

                if (nextData) {
                    const chunks: string[] = [nextData.text || nextData.items].filter(Boolean).flat();
                    answerChunks = answerChunks.concat(chunks);
                }

                nextIndex++;
            }

            if (answerChunks.length) {
                faqItems.push({
                    title: item.data.text,
                    text: answerChunks.join('\n'),
                });
            }

            index = nextIndex;
        }

        return faqItems.map((item, i) => ({
            '@type': 'Question',
            '@id': `${url}#hasPart_FAQPage_mainEntity${i + 1}`,
            acceptedAnswer: [{
                '@type': 'Answer',
                '@id': `${url}#hasPart_FAQPage_mainEntity${i + 1}_acceptedAnswer_Answer`,
                text: item.text,
            }],
            name: item.title,
        }));
    }
}
