<template>
    <div class="menu-tab-solutions">
        <menu-tab v-bind="$props" />
    </div>
</template>

<script lang="ts">
import MenuTabMixin from './menu-tab-mixin.vue';

export default {
    name: 'MenuTabSolutions',
    mixins: [MenuTabMixin],
};
</script>

<style lang="postcss" scoped>
.menu-tab-solutions {
    --aside-width: 100%;
    --aside-width-desktop: 276px;
    --aside-width-desktop-wide: 356px;
    --aside-width-desktop-large: 370px;
    &:deep(.s-menu-tab) {
        display: flex;
        flex-flow: row wrap;
        .inner-tabs {
            width: var(--aside-width);
            padding: 0 16px 24px 16px;
            @media (--viewport-desktop) {
                padding: 24px 32px;
            }
            @media (--viewport-desktop) {
                width: var(--aside-width-desktop);
                padding: 32px;
            }
            @media (--viewport-desktop-wide) {
                width: var(--aside-width-desktop-wide);
                padding: 32px 64px;
            }
            @media (--viewport-desktop-large) {
                width: var(--aside-width-desktop-large);
                padding: 32px 64px;
            }
        }
        .s-menu-tab-wrapper {
            width: var(--aside-width);
            @media (--viewport-desktop) {
                width: calc(100% - var(--aside-width-desktop));
            }
            @media (--viewport-desktop-wide) {
                width: calc(100% - var(--aside-width-desktop-wide));
            }
            @media (--viewport-desktop-large) {
                width: calc(100% - var(--aside-width-desktop-large));
            }
        }
        .s-menu-tab__body_type_solutions {
            justify-content: flex-start;
            padding-inline: 16px;
            gap: 16px;
            @media (--viewport-tablet) {
                padding-inline: 32px;
            }
            @media (--viewport-desktop) {
                padding-inline: 16px;
            }
            @media (--viewport-desktop-wide) {
                padding-inline-start: 48px;
                padding-inline-end: 64px;
                gap: 40px;
            }
            .s-menu-tab__section_type_solutions.s-menu-tab__section_tabindex_0.s-menu-tab__section_divider_wide {
                grid-column-end: 3;
                grid-column-start: 1;
                .s-menu-tab__list {
                    display: grid;
                    grid-template-columns: 2fr;
                    @media (--viewport-tablet) {
                        grid-template-columns: 1fr 1fr;
                        padding-right: 16px;
                    }
                    @media (--viewport-desktop) {
                        padding-right: 32px;
                    }
                }
            }
            .s-menu-tab__section_tabindex_1 {
                flex-grow: 1;
            }
        }
        &__section {
            &_divider_wide {
                &:not(:last-child) {
                    @media screen and (max-width: 767px) {
                        &::before {
                            display: none;
                        }
                    }
                }

                @media (--viewport-tablet) {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 24px;
                        inset-inline-start: 100%;
                        background: var(--av-brand-secondary-light);
                        width: 1px;
                        height: calc(100% - 108px);
                    }
                }
            }

        }
        &__list {
            @media (--viewport-tablet) {
                padding-right: 16px;
            }
            @media (--viewport-desktop) {
                padding-right: 32px;
            }
        }
        &__list-item-link,
        &__linkmore {
            .a-link__content {
                @mixin lead;

                @media (--viewport-tablet) {
                    @mixin paragraph;
                }
            }
        }

        &__linktitle {
            pointer-events: none;

            .a-link__content {
                @mixin title-accent;
                color: var(--av-nav-primary);

                @media (--viewport-tablet) {
                    @mixin lead-accent;
                }
            }
        }
    }
}
</style>
