<template>
    <div class="wrapper">
        <a-picture :background="background" is-background fit="cover" />
        <s-basic-slice class="s-header-partners" :class="isLight ? 'theme-light' : 'theme-dark'">
            <div class="s-header-partners__content">
                <div class="s-header-partners__top">
                    <h1 class="s-header-partners__product">
                        {{ productName }}
                    </h1>
                    <h3 v-if="subname" class="s-header-partners__subname">
                        {{ subname }}
                    </h3>
                </div>
                <ul v-if="links" class="s-header-partners__links">
                    <li v-for="(link, index) in links" :key="index" class="s-header-partners__link-item">
                        <a-link
                            v-bind="link"
                            :glyph-position="link.glyphPosition || 'left'"
                            :size="link.size || 'paragraph'"
                            :type="isLight ? 'regular' : 'light'"
                        />
                    </li>
                </ul>
                <div v-if="updateText" class="s-header-partners__update-text">
                    {{ updateText }}
                </div>
                <div class="s-header-partners__info">
                    <div v-if="title" class="s-header-partners__title">
                        {{ title }}
                    </div>
                    <div v-if="buttons && buttons.length" class="s-header-partners__buttons">
                        <div
                            v-for="(button, index) in buttons"
                            :key="button.main"
                            class="s-header-partners__button-wrapper"
                        >
                            <a-button
                                class="s-header-partners__button"
                                v-bind="button"
                                :glyph="button.glyph || 'arrow'"
                                :size="button.size || 's'"
                                :type="button.type || getButtonType(index)"
                            />
                            <div v-if="button.disclaimer" class="s-header-partners__button-disclaimer">
                                {{ button.disclaimer }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </s-basic-slice>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SHeaderPartners',

    components: {
        SBasicSlice,
        AButton,
        ALink,
        APicture,
    },

    props: {
        isLight: {
            type: Boolean,
            default: true,
        },

        background: {
            type: Object,
            default: () => ({
                link: '',
                ext: '',
            }),
        },

        productName: {
            type: String,
            required: true,
        },

        subname: {
            type: String,
            default: '',
        },

        links: {
            type: [Array, Boolean],
            default: () => [],
        },

        cards: {
            type: Array,
            default: () => [],
        },

        updateText: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        buttons: {
            type: Array,
            default: () => [],
            validator: (buttons) => buttons.length < 3,
        },
    },

    methods: {
        getButtonType(index) {
            if (index === 0) {
                if (this.buttons.length === 1) return 'action';
                return 'main';
            }

            return 'action';
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-header-partners {
    width: 100%;
    height: 800px;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 51px;
    padding-bottom: 24px;

    &.theme-light {
        background-color: var(--av-solid-brand-accent);

        .s-header-partners__product {
            color: var(--av-nav-primary);
        }
    }

    &.theme-dark {
        background-color: var(--av-nav-primary);
        color: var(--av-inversed-primary);
    }

    &__info {
        grid-row-start: 4;
        -ms-grid-row: 4;
        width: 100%;
    }

    &__top {
        width: 50%;
        -ms-grid-row: 1;
        @media (--viewport-mobile-wide) {
            width: 100%;
        }
    }

    &__links {
        -ms-grid-row: 2;
    }

    &__content {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows:
            minmax(min-content, 400px) minmax(100px, max-content)
            24px 1fr;
        -ms-grid-columns: 100%;
        -ms-grid-rows: minmax(min-content, 400px) minmax(100px, max-content) 24px 1fr;
        height: 100%;
    }

    &__product {
        @mixin hero;
        margin: 16px 0;
        font-weight: 300;

        @media (--viewport-desktop-wide) {
            @mixin large;
            font-weight: 300;
        }
    }

    &__subname {
        font-weight: 600;
        color: var(--av-fixed-success);
    }

    &__label-flag {
        font-weight: 600;
        color: var(--av-fixed-success);
    }

    &__info-flag {
        font-weight: 600;
        color: var(--av-inversed-light);
    }

    &__price {
        @mixin title;

        @media (--viewport-tablet) {
            @mixin display;
        }

        margin-bottom: 24px;
    }

    &__block {
        &:first-child {
            margin-bottom: 40px;
        }
    }

    &__title {
        @mixin title;

        margin-bottom: 48px;

        @media (--viewport-desktop) {
            @mixin display;
        }

        @media (--viewport-desktop-wide) {
            @mixin hero;
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    &__link {
        &_call {
            color: var(--av-inversed-primary);
        }
    }

    &__update-text {
        align-self: flex-end;
        font-weight: 600;
        color: var(--av-fixed-success);
    }

    &__buttons {
        margin-bottom: 24px;
    }

    &__button-wrapper {
        position: relative;

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        width: 100%;

        .a-glyph {
            display: none;
        }
    }

    &__button-disclaimer {
        @mixin caption;

        display: none;
        position: absolute;
        width: 100%;
        margin-top: 8px;
        text-align: center;
        color: var(--av-inversed-light);
    }

    @media (--viewport-mobile-wide) {
        &__buttons {
            display: flex;
        }

        &__button-wrapper {
            width: calc(50% - 8px);
            min-width: 164px;

            &:not(:first-child) {
                margin-inline-start: 16px;
                margin-top: 0;
            }
        }

        &__button {
            display: inline-flex;
            justify-content: space-between;

            &:deep(.a-glyph) {
                display: block;
            }
        }
    }

    @media (--viewport-tablet) {
        height: 686px;
        padding-top: 67px;
        padding-bottom: 40px;

        &__content {
            grid-template-rows:
                minmax(min-content, 350px) minmax(100px, max-content)
                24px 1fr;
            -ms-grid-rows: minmax(min-content, 350px) minmax(100px, max-content) 24px 1fr;
        }

        &__bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &:first-child {
                margin-bottom: 40px;
            }
        }

        &__block {
            display: flex;

            &:first-child {
                align-items: flex-end;
                margin-bottom: 24px;
            }
        }

        &__price,
        &__links {
            flex-grow: 0;
            flex-shrink: 0;
            padding-inline-end: 16px;
        }

        &__price {
            margin-bottom: 0;
        }

        &__links {
            display: block;
        }

        &__link-item:not(:last-child) {
            margin-bottom: 16px;
        }

        &__buttons {
            margin-bottom: 16px;
        }

        &__button-wrapper {
            width: auto;
        }

        &__button {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    @media (--viewport-desktop) {
        height: 600px;
        padding-top: 72px;
        padding-bottom: 40px;

        &__content {
            grid-template-columns: 4fr 8fr;
            -ms-grid-columns: 4fr 8fr;
            grid-template-rows:
                minmax(min-content, 400px) minmax(100px, max-content)
                24px 1fr;
            -ms-grid-rows: minmax(min-content, 400px) minmax(100px, max-content) 24px 1fr;

            .s-header-partners__links {
                grid-row-start: 2;
                -ms-grid-row: 2;
            }

            .s-header-partners__update-text {
                grid-row-start: 2;
                -ms-grid-row: 2;
                align-self: flex-start;
                margin-top: 16px;
            }
        }

        &__links {
            -ms-grid-row: 2;
            padding-top: 32px;
        }

        &__card-wrapper {
            grid-row-start: 2;
            -ms-grid-row: 2;
            -ms-grid-column: 2;
            align-self: center;
        }

        &__info {
            grid-row-start: 2;
            -ms-grid-row: 2;
            -ms-grid-column: 2;
            align-self: center;
        }

        &__actions {
            display: flex;
        }

        &__block {
            &:last-child {
                align-items: center;
            }
        }

        &__button-wrapper {
            min-width: 147px;
        }

        &__buttons {
            margin-inline-end: 16px;
            margin-bottom: 0;
        }

        &__button-disclaimer {
            display: block;
        }
    }

    @media (--viewport-desktop-wide) {
        &__product {
            max-width: 373px;
        }

        &__update-text {
            align-self: flex-end;
        }

        &__button-wrapper {
            width: 180px;
        }
    }

    @media (--viewport-desktop-large) {
        &__product {
            max-width: 426px;
        }

        &__button-wrapper {
            width: 205px;
        }
    }
}
</style>
