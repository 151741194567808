<template>
    <div v-show="isShown" class="s-locale-selector">
        <div class="s-locale-selector__content">
            <div class="s-locale-selector__content__close">
                <button class="s-locale-selector__content__close-button" tabindex="0" @click="$emit('close')">
                    <a-glyph class="s-locale-selector__content__close-icon" name="close" size="m" />
                </button>
            </div>
            <div class="s-locale-selector__content__title">
                <div>{{ locales.title }}</div>
            </div>
            <div class="s-locale-selector__content__main">
                <div
                    v-for="column in locales.columns"
                    :key="column.name"
                    class="column s-locale-selector__content__main__column"
                >
                    <div
                        v-for="region in column.regions"
                        :key="region.name"
                        class="region s-locale-selector__content__main__region"
                    >
                        <div class="s-locale-selector__content__main__region__title">
                            {{ region.name }}
                        </div>
                        <ul class="s-locale-selector__content__main__region__list">
                            <li
                                v-for="locale in region.locales"
                                :key="`${locale.country}-${locale.language}`"
                                class="country s-locale-selector__content__main__region__list__item"
                            >
                                <div
                                    v-if="isLocaleActive(locale.isoCode)"
                                    class="s-locale-selector__content__main__region__list__item__link active-locale-item"
                                >
                                    <span class="s-locale-selector__country">{{ locale.country }}</span>
                                    <span class="s-locale-selector__language">{{ locale.language }}</span>
                                </div>
                                <a
                                    v-else
                                    ref="itemLink"
                                    class="link-item s-locale-selector__content__main__region__list__item__link"
                                    tabindex="0"
                                    :href="locale.externalURL || `/${locale.isoCode}/`"
                                    @click.prevent.stop="onLocaleClick(locale)"
                                >
                                    <span class="s-locale-selector__country">{{ locale.country }}</span>
                                    <span class="s-locale-selector__language">{{ locale.language }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <a-accordion class="s-locale-selector__accordion" one-only-mode>
                <template v-for="column in locales.columns">
                    <a-accordion-item
                        v-for="region in column.regions"
                        :key="region.name"
                        class="s-locale-selector__accordion__item"
                        :class="{ 'a-accordion__item_active': isRegionActive(region) }"
                    >
                        <template #title>
                            <div class="s-locale-selector__accordion__title">
                                <a-glyph
                                    class="s-locale-selector__accordion__title__icon icon"
                                    name="icon-pointer-right"
                                />
                                <a-dangerous-html
                                    class="s-locale-selector__accordion__title__text"
                                    :content="region.name"
                                />
                            </div>
                        </template>
                        <ul class="s-locale-selector__accordion__content">
                            <li
                                v-for="locale in region.locales"
                                :key="`${locale.country}-${locale.language}`"
                                class="s-locale-selector__accordion__content__item"
                            >
                                <a
                                    class="s-locale-selector__accordion__content__link"
                                    :class="{ 'active-locale-item': isLocaleActive(locale.isoCode) }"
                                    :href="locale.externalURL || `/${locale.isoCode}/`"
                                    tabindex="0"
                                    @click.prevent.stop="onLocaleClick(locale)"
                                >
                                    <span class="s-locale-selector__country">{{ locale.country }}</span>
                                    <span class="s-locale-selector__language">{{ locale.language }}</span>
                                </a>
                            </li>
                        </ul>
                    </a-accordion-item>
                </template>
            </a-accordion>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import locales from '@core/mixins/locales.js';

export default {
    name: 'SLocaleSelector',
    components: {
        AAccordion,
        AAccordionItem,
        ADangerousHtml,
        AGlyph,
    },
    mixins: [locales],
    props: {
        /**
         * GA Event
         */
        event: {
            type: Object,
            default: () => ({}),
        },

        isShown: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close'],
    data() {
        return {
            isMounted: false,
        };
    },
    computed: {
        locales() {
            return this.$store.state.slices.items?.['s-locale-selector'] || {};
        },
    },
    watch: {
        isShown(isShown) {
            if (!this.isMounted) return;

            if (isShown) {
                window.addEventListener('keydown', this.keyDownHandler);
                this.previousBodyOverflowState = document.body.style.overflow;
                document.body.style.overflow = 'hidden';
                this.addKeyboardFocusControl();
            } else {
                window.removeEventListener('keydown', this.keyDownHandler);
                document.body.style.overflow = this.previousBodyOverflowState;
            }
        },
    },
    mounted() {
        this.isMounted = true;
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.keyDownHandler);
        document.body.style.overflow = this.previousBodyOverflowState;
    },
    methods: {
        keyDownHandler(e) {
            switch (e.key) {
                case 'Escape':
                    this.$emit('close');
                    break;
                case ' ':
                    e.target?.click();
                    break;
                default:
                    break;
            }
        },

        linkKeyUpHandler(e, i) {
            let itemToFocus;
            switch (e.key) {
                case 'ArrowLeft':
                    itemToFocus = this.$refs.itemLink[i - 1];
                    break;
                case 'ArrowRight':
                    itemToFocus = this.$refs.itemLink[i + 1];
                    break;
                default:
                    break;
            }
            itemToFocus?.focus();
        },

        addKeyboardFocusControl() {
            this.$refs.itemLink.forEach((link, i) => {
                link.addEventListener('keyup', (e) => this.linkKeyUpHandler(e, i));
            });
        },

        isLocaleActive(locale) {
            return this.$route && locale && this.$route.params.locale === locale;
        },

        isRegionActive(region) {
            return (
                this.$route &&
                region.locales.map((x) => x.isoCode).includes(this.$route.params.locale)
            );
        },

        async redirectToLocale(locale) {
            const pathSplit = this.$route.path.split('/').filter(Boolean);
            pathSplit.shift(); // Remvoes locale
            let url = `/${locale.isoCode}/${pathSplit.join('/')}`;
            url += url.endsWith('/') ? '' : '/';

            const opts = {
                timeout: 10000,
                validateStatus: (status) => status === StatusCodes.OK,
                method: 'HEAD',
                url,
            };

            try {
                await axios.request(opts);
                window.location.href = url;
            } catch {
                window.location.href = `/${locale.isoCode}/`;
            }
        },

        onLocaleClick(locale) {
            if (this.isLocaleActive(locale.isoCode)) return;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'Menu Language',
                eventLabel: locale.externalURL || `/${locale.isoCode}/`,
                eventContext: 'switch',
                ...this.event,
            });

            if (locale.externalURL) {
                window.location = locale.externalURL;
                return;
            }

            this.storeLocaleInCookies(locale);
            this.redirectToLocale(locale);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-locale-selector {
    position: fixed;
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    top: 0;

    /* WEB-43924 why do we use 1000 here ? */
    z-index: 1000;
    background: var(--av-inversed-primary);
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-inline: 16px 32px;

    @media (--viewport-mobile-wide) {
        padding-inline: 32px 48px;
    }

    @media (--viewport-tablet) {
        padding-top: 36px;
        padding-bottom: 48px;
        padding-inline: 32px;
    }

    @media (--viewport-desktop) {
        padding-top: 40px;
    }

    @media (--viewport-desktop-wide) {
        padding-top: 48px;
        padding-inline: 64px;
    }

    &__country {
        @mixin paragraph-accent;

        color: var(--av-brand-primary);
        padding-inline-end: 8px;
    }
    &__language {
        @mixin paragraph;

        color: var(--av-fixed-secondary);
    }
    &__content {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        margin: 0 auto;
        max-width: 1280px;
        flex-direction: column;

        &__title {
            @mixin title;

            color: var(--av-nav-primary);
            text-align: start;
            margin-bottom: 20px;
            padding-inline-end: 32px;

            @media (--viewport-tablet) {
                margin-bottom: 36px;
            }

            @media (--viewport-desktop) {
                @mixin display;

                margin-bottom: 40px;
            }

            @media (--viewport-desktop) {
                margin-bottom: 48px;
            }
        }

        &__close {
            position: sticky;
            top: 0;
        }

        &__close-button {
            position: absolute;
            border: none;
            background: none;
            margin: 0;
            padding: 8px;
            cursor: pointer;
            top: 0;
            inset-inline-end: -16px;

            @media (--viewport-tablet) {
                top: 0;
                inset-inline-end: 0;
            }

            @media (--viewport-desktop-wide) {
                top: 0;
                inset-inline-end: 0;
            }

            &::before {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                inset-inline-start: 0;
                inset-inline-end: 0;
                bottom: 0;
                border-radius: 4px;
                background: transparent;
                transition: background 0.2s ease;
            }

            &:hover {
                &::before {
                    background: var(--av-brand-secondary-accent);
                }
            }

            &:active {
                &::before {
                    background: var(--av-brand-secondary-light);
                    border: 0 !important;
                }
            }

            &:focus-visible {
                &::before {
                    border: 3px solid var(--av-fixed-focus);
                }
            }
        }

        &__close-icon {
            fill: var(--av-brand-primary);
            display: block;
        }

        &__main {
            background-size: contain;
            margin: 0 auto;
            display: none;
            justify-content: space-between;
            flex-grow: 1;
            width: 100%;

            @media (--viewport-tablet) {
                display: block;
            }

            &__column:not(:last-child) {
                .s-locale-selector__content__main__region {
                    border-bottom: 1px solid var(--av-brand-accent);
                    margin-bottom: 24px;
                    padding-bottom: 16px;

                    @media (--viewport-desktop-wide) {
                        margin-bottom: 24px;
                        padding-bottom: 24px;
                    }

                    @media (--viewport-desktop-large) {
                        display: grid;
                        grid-template-columns: 1fr 3fr;
                        grid-column-gap: 16px;
                        margin-bottom: 24px;
                    }
                }
            }

            &__region {
                @media (--viewport-desktop-large) {
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                    grid-column-gap: 24px;
                }

                &__title {
                    @mixin lead-accent;

                    color: var(--av-nav-primary);
                    margin-bottom: 16px;

                    @media (--viewport-desktop-large) {
                        padding-top: 8px;
                        margin-bottom: 0;
                    }
                }

                &__list {
                    columns: 2 auto;
                    column-gap: 24px;

                    @media (--viewport-desktop) {
                        columns: 3 auto;
                    }

                    &__item {
                        @media (--viewport-desktop) {
                            margin-bottom: 0;
                        }

                        &__link {
                            position: relative;
                            display: inline-block;
                            width: 100%;
                            text-decoration: none;
                            padding-top: 8px;
                            padding-bottom: 8px;
                            padding-inline: 16px;

                            &::before {
                                display: block;
                                content: '';
                                top: 0;
                                inset-inline-start: 0;
                                inset-inline-end: 0;
                                bottom: 0;
                                border-radius: 4px;
                                position: absolute;
                                background: transparent;
                                transition: background 0.2s ease;
                            }

                            &:hover {
                                &::before {
                                    background: var(--av-brand-secondary-accent);
                                }
                            }

                            &:active {
                                &::before {
                                    background: var(--av-brand-secondary-light);
                                    border: 0 !important;
                                }
                            }

                            &:focus-visible {
                                &::before {
                                    border: 3px solid var(--av-fixed-focus);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__accordion {
        @media (--viewport-tablet) {
            display: none;
        }
        &__content {
            a {
                text-decoration: none;
            }
            &__link {
                position: relative;
                display: block;
                padding-top: 8px;
                padding-bottom: 8px;
                padding-inline-end: 16px;

                &::before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    inset-inline-start: -16px;
                    inset-inline-end: 0;
                    bottom: 0;
                    border-radius: 4px;
                    background: transparent;
                    transition: background 0.2s ease;
                }

                &:active {
                    &::before {
                        background: var(--av-brand-secondary-light);
                        border: 0 !important;
                    }
                }

                &:focus-visible {
                    &::before {
                        border: 3px solid var(--av-fixed-focus);
                    }
                }
            }
        }
        &__title {
            display: flex;
            &__text {
                @mixin paragraph-accent;

                display: inline-block;
            }
            &__icon {
                fill: var(--av-brand-secondary);
            }
        }
    }

    /* Accordion defaults fix */
    &:deep(.a-accordion) {
        > div:not(:last-child) {
            .a-accordion-item {
                &::after {
                    display: block;
                    position: absolute;
                    content: '';
                    height: 1px;
                    background: var(--av-brand-accent);
                    inset-inline-start: -16px;
                    inset-inline-end: -32px;
                    bottom: 0;

                    @media (--viewport-mobile-wide) {
                        inset-inline-start: -32px;
                        inset-inline-end: -48px;
                    }
                }
            }
        }

        .icon {
            fill: var(--av-brand-primary);
        }

        .a-accordion-item {
            position: relative;
            border: 0 !important;

            .title {
                padding: 16px 0 8px;
                color: var(--av-fixed-primary);
            }

            &.active {
                padding-bottom: 16px;
            }
        }

        .content {
            position: relative;
            padding-inline-start: 24px;

            li {
                margin-bottom: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .content-wrapper {
            padding: 8px 0 0;
        }
    }

    .active-locale-item {
        position: relative;
        pointer-events: none;

        .s-locale-selector__country,
        .s-locale-selector__language {
            color: var(--av-fixed-primary);
        }

        &::before {
            background: var(--av-brand-secondary-accent);

            @media (--viewport-tablet) {
                inset-inline-start: 0;
            }
        }
    }
}
</style>
