<template>
    <s-basic-slice class="s-purchasing-m365" v-bind="$attrs">
        <div class="wrapper">
            <div class="content">
                <a-label v-if="promo.isPromoActive && settings.label" v-bind="settings.label">
                    {{ settings.label.text }}
                </a-label>
                <div class="products">
                    <div v-for="(product, index) in productList" :key="`product-${index}`" class="product-item">
                        <p v-if="product.title" class="product-title" v-html="product.title" />
                        <div class="selectors-wrapper">
                            <p v-if="product.benefit" class="benefit" v-html="product.benefit" />
                            <div class="selectors">
                                <div class="selector-item">
                                    <p class="selector-title" v-html="dictionary('Seats')" />
                                    <div class="quantity">
                                        <ANumberInput
                                            :step="product.step || 1"
                                            :min="product.step || 1"
                                            :max="product.quantity.max || 999"
                                            @change="product.selected.quantity = $event"
                                        />
                                        <div class="multiplier" v-html="'×'" />
                                        <div class="license-price" v-html="productPrice(product, index)" />
                                    </div>
                                </div>
                                <div class="selector-item">
                                    <p class="selector-title" v-html="dictionary('Subscription period')" />
                                    <s-selector
                                        :list="listSelector(index)"
                                        :selected="Number(product.selected.period)"
                                        @change="product.selected.period = $event.target.value"
                                    />
                                </div>
                            </div>
                        </div>
                        <p v-if="product.information" class="information" v-html="product.information" />
                    </div>
                </div>
                <div v-if="settings.list?.length" class="list">
                    <p v-for="(item, itemList) in settings.list" :key="`list-item-${itemList}`" class="list-item">
                        <a-link v-if="item.to" v-bind="item" />
                        <span v-else v-html="item.text" />
                    </p>
                </div>
            </div>
            <div class="order">
                <p class="order-title" v-html="dictionary('Order summary')" />
                <div v-for="(product, index) in productList" :key="`order-product-${index}`" class="order-product">
                    <div v-if="product.title" class="order-product-item">
                        <div class="order-product-title">
                            {{ product.title }}
                        </div>
                        <div class="order-product-price">
                            <div
                                v-if="product.discount && product.selected.quantity"
                                class="order-product-price-full"
                                v-html="productPriceFull(product)"
                            />
                            <div class="order-product-price-current" v-html="productPriceCurrent(product)" />
                        </div>
                        <div class="order-product-remove" @click="product.selected.quantity = 0">
                            <a-glyph v-if="product.selected.quantity" name="trash-o" />
                        </div>
                    </div>
                    <div class="order-product-quantity">
                        {{ dictionary('Quantity') }}: {{ product.selected.quantity }} {{ dictionary('QuantitySuffix') }}
                    </div>
                    <div class="order-product-period">
                        {{ dictionary('License period') }}:
                        {{ dictionary(`${product.selected.period}Y-period`) }}
                    </div>
                </div>
                <div class="total">
                    <div class="total-item">
                        <p class="total-text" v-html="dictionary('Total')" />
                        <p class="total-vat" v-html="dictionary('VAT')" />
                    </div>
                    <div class="total-item total-current" v-html="productPriceTotal()" />
                </div>
                <a-button
                    v-bind="settings?.button"
                    :disabled="!selectedProducts.length"
                    :text="dictionary('Proceed to checkout')"
                    @click="handleClick"
                />
            </div>
        </div>
        <a-loader :is-visible="isVisibleSURL" />
    </s-basic-slice>
</template>

<script>
import { cloneDeep, merge, isInteger } from 'lodash';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import ALoader from '@core/components/loader/loader.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import currency from '@core/mixins/currency.js';
import purchasing from '@core/mixins/purchasing.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import ANumberInput from '@core/slices/pages/purchasing-cyber-protect/version-2/components/num-picker.vue';
import SSelector from '@core/slices/pages/purchasing-snap-deploy/components/selector.vue';

export default {
    name: 'SPurchasingM365',
    components: {
        AButton,
        AGlyph,
        ALabel,
        ALink,
        ALoader,
        SBasicSlice,
        ANumberInput,
        SSelector,
    },
    mixins: [purchasing, currency, analytics, cleverbridge],
    props: {
        lead: {
            type: String,
            default: null,
        },
        settings: {
            type: Object,
            default: null,
        },
        purchasePageCTA: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            coupon: '',
            promo: {},
            slice: [],
        };
    },
    computed: {
        productList() {
            return this.slice.products || [];
        },
        selectedProducts() {
            return this.productList.filter((product) => product.selected.quantity);
        },
    },
    created() {
        this.slice = cloneDeep(this.settings);
    },
    mounted() {
        this.coupon = this.$route.query?.coupon || '';
        if (this.coupon && this.activeCoupon.discount) {
            const list = this.activeCoupon.cleverBridgeCartList || '';
            this.promo = {
                ...this.promo,
                ...this.activeCoupon,
                isPromoActive: true,
                cleverBridgeCartList: list.split(',').map(Number),
            };
        } else {
            const isPurchase = true;
            const cta = merge(this.productCTA({ isPurchase }), this.purchasePageCTA);
            const isPromoActive = !cta.doNotApplyPromo && this.checkPromo({ ...cta.promo, isPurchase });
            this.promo = { ...cta.promo, isPurchase, isPromoActive };
        }
    },
    methods: {
        hasMaxQuantity(product) {
            return product.licenses.find((item) => item.maxQuantity);
        },
        dictionary(item) {
            return this.settings?.dictionary?.[item] || item;
        },
        setPrice(price) {
            return this.setCurrency(price, { zeroByDefault: true });
        },
        findLicense(cleverBridgeCartID) {
            const license = this.licenseData({ cleverBridgeCartID, promo: this.promo });
            return { price: license.price || 0, discount: license.discount_absolute || 0 };
        },
        listSelector(index) {
            const product = this.productList[index];
            const license = product.licenses.find((item) => item.period === 1);
            const annual = this.findLicense(license.cleverBridgeCartID);
            const labels = product.licenses.map((item, i) => {
                if (item.maxQuantity || (!i && !this.promo.isPromoActive)) return '';
                const text = this.dictionary('Save XX%');
                const current = this.findLicense(item.cleverBridgeCartID);
                const price = this.promo.isPromoActive ? current.price + current.discount : annual.price * item.period;
                const result = Math.round((100 * (price - current.price)) / price);
                return result ? `<span class="promo">${text.replace('XX', result)}</span>` : '';
            });
            const result = product.licenses.map((item, i) => ({
                id: item.period,
                text: `<span>${this.dictionary(`${item.period}Y-period`)}</span>${labels[i]}`,
            }));
            return [...new Map(result.map((item) => [item.id, item])).values()];
        },
        listPeriod(product) {
            const period = Number(product.selected.period);
            return product.licenses.filter((item) => item.period === period);
        },
        productPrice(product, index) {
            const period = product.period || product.selected.period;
            let current = (product?.licenses || []).find((item) => item.period === Number(period)) || product;
            if (isInteger(index) && this.hasMaxQuantity(product)) {
                const compare = (item) => product.selected.quantity < item.maxQuantity;
                current = this.listPeriod(product).find(compare);
            }
            const cleverBridgeCartID = current.cleverBridgeCartID;
            const { price, discount } = this.findLicense(cleverBridgeCartID);
            if (isInteger(index)) {
                this.productList[index].price = price;
                this.productList[index].discount = discount;
                this.productList[index].cleverBridgeCartID = cleverBridgeCartID;
            }
            const priceCurrent = this.setPrice(price);
            const priceFull = `<del>${this.setPrice(price + discount)}</del>`;
            return discount ? `${priceFull}<span>${priceCurrent}</span>` : priceCurrent;
        },
        productQuantity(product) {
            return product.selected.quantity / (product.step || 1);
        },
        productPriceCurrent(product) {
            return this.setPrice(product.price * this.productQuantity(product));
        },
        productPriceFull(product) {
            return this.setPrice((product.price + product.discount) * this.productQuantity(product));
        },
        productPriceTotal() {
            const result = this.selectedProducts
                .map((product) => product.price * this.productQuantity(product) || 0)
                .reduce((sum, price) => sum + price, 0);
            return this.setPrice(result);
        },
        handleClick() {
            const options = this.storeOptionsSURL({
                shopId: 837,
                segment: 'corporate',
                params: {
                    scope: 'checkout',
                    ...this.pageOptionsSURL,
                },
            });
            if (this.promo?.isPromoActive && this.promo?.coupon) {
                options.params.coupon = this.promo.coupon;
            }
            if (this.activeCoupon.coupon) {
                options.params.coupon = this.activeCoupon.coupon;
            }
            this.selectedProducts.forEach((product) => {
                const separator = options.params.cart ? ',' : '';
                const quantity = this.productQuantity(product);
                options.params.cart += `${separator}${product.cleverBridgeCartID}`;
                options.params[`quantity_${product.cleverBridgeCartID}`] = quantity;
                const minQuantity = Math.min(...product.licenses.map((item) => item.maxQuantity));
                if (minQuantity && quantity >= minQuantity) {
                    options.params.lockcart = true;
                }
                const license = this.licenseData({
                    promo: this.promo,
                    cleverBridgeCartID: product.cleverBridgeCartID,
                });
                this.ecommerceSendEvent({
                    license,
                    event: {
                        quantity,
                        eventLabel: license.name || '',
                        eventAction: 'Add to cart',
                        ecommerce: {
                            type: 'add',
                            license: license.cleverbridge_id,
                        },
                    },
                });
            });
            this.redirectToCleverbridge(options);
        },
    },
    async serverPrefetch() {
        await this.findCleverBridgeCart(this.settings.products, { ...this.purchasePageCTA, isPurchase: true });
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-m365 {
    padding-top: 48px;
    padding-bottom: 72px;
    &:deep(.a-slice-header) {
        margin: 0;
        width: 100%;
        .a-slice-header__title {
            @mixin display-accent;
            color: var(--av-nav-primary);
            @media (--viewport-desktop) {
                @mixin hero-accent;
            }
        }
    }
}
.wrapper {
    margin-top: 40px;
    @media (--viewport-tablet) {
        display: flex;
        margin: 40px 0 0;
        justify-content: space-between;
    }
}
.content {
    position: relative;
    @media (--viewport-tablet) {
        margin: 0;
        width: 100%;
        margin-inline-end: 16px;
    }
    &:deep(.a-label) {
        top: -12px;
        left: 16px;
        position: absolute;
        @media (--viewport-tablet) {
            left: 24px;
        }
    }
}
.products {
    border-radius: 4px;
    color: var(--av-nav-primary);
    border: 1px solid var(--av-brand-light);
    box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
    background: var(--av-solid-brand-lightest);
    .product-item {
        width: 100%;
        padding: 24px 0;
        user-select: none;
        .product-title {
            @mixin title-accent;
            padding: 0 16px;
            color: var(--av-nav-primary);
            @media (--viewport-tablet) {
                padding: 0 24px;
            }
        }
        .benefit {
            @mixin paragraph-accent;
            margin-bottom: 16px;
            color: var(--av-button-action-hover);
        }
        .information {
            @mixin caption;
            padding: 16px 16px 0;
            color: var(--av-fixed-secondary);
            @media (--viewport-tablet) {
                padding: 16px 24px 0;
            }
            @media (--viewport-desktop) {
                padding: 24px 24px 0;
            }
        }
    }
}
.license-price {
    @mixin lead-accent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--av-nav-primary);
    @media (--viewport-desktop-large) {
        flex-direction: row;
        align-items: center;
    }
    &:deep(del) {
        @mixin paragraph;
        font-weight: 400;
        position: relative;
        text-decoration: none;
        color: var(--av-fixed-light);
        @media (--viewport-desktop-large) {
            margin-bottom: 0;
            margin-inline-end: 8px;
        }
        &:before {
            inset-inline-start: 0;
            top: 50%;
            height: 1px;
            content: "";
            width: 100%;
            position: absolute;
            background: var(--av-fixed-light);
        }
    }
}
.selectors-wrapper {
    margin-top: 24px;
    padding: 24px 16px 16px;
    border-top: 1px solid var(--av-brand-light);
    border-bottom: 1px solid var(--av-brand-light);
    @media (--viewport-tablet) {
        padding: 24px 24px 16px;
    }
    .selectors {
        gap: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        @media (--viewport-desktop) {
            flex-direction: row;
        }
        .selector-item {
            width: 100%;
            @media (--viewport-desktop) {
                width: auto;
            }
            &:deep(.selector-wrapper) {
                @media (--viewport-mobile-wide) {
                    width: 328px;
                }
            }
        }
        .selector-title {
            @mixin lead-accent;
            color: var(--av-nav-primary);
        }
    }
}
.quantity {
    height: 48px;
    display: flex;
    user-select: none;
    align-items: center;
    .multiplier {
        margin: 0 8px;
    }
    &:deep(.number-wrapper) {
        background: white;
        .number-input {
            height: 48px;
        }
        .decrement,
        .increment {
            border: none;
            min-width: 36px;
            position: relative;
            &:before {
                top: 0;
                left: 0;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
            }
            &.disabled {
                background: transparent;
            }
            .a-glyph {
                width: 18px;
                height: 18px;
                max-width: none;
                max-height: none;
            }
        }
        input {
            @mixin lead;
            width: auto;
        }
    }
}
.order {
    margin-top: 64px;
    color: var(--av-nav-primary);
    @media (--viewport-tablet) {
        margin: 0;
        width: 100%;
        max-width: 284px;
    }
    @media (--viewport-desktop) {
        max-width: 308px;
    }
    @media (--viewport-desktop-wide) {
        max-width: 372px;
    }
    .order-title {
        @mixin title-accent;
    }
    .order-product {
        margin-top: 8px;
        padding: 8px 0 16px;
        border-top: 1px solid var(--av-brand-secondary-light);
    }
    .order-product-item {
        display: flex;
        margin-bottom: 4px;
        justify-content: space-between;
    }
    .order-product-title {
        @mixin paragraph-accent;
        width: 100%;
        @media (--viewport-desktop) {
            @mixin lead-accent;
        }
    }
    .order-product-price {
        @mixin lead-strong;
        display: flex;
        min-width: 60px;
        flex-direction: column;
        align-items: flex-end;
    }
    .order-product-price-full {
        @mixin paragraph;
        font-weight: 400;
        position: relative;
        color: var(--av-fixed-secondary);
        &:before {
            inset-inline-start: 0;
            top: 58%;
            height: 1px;
            content: "";
            width: 100%;
            position: absolute;
            background: var(--av-fixed-light);
        }
    }
    .order-product-price-current {
        @mixin lead-accent;
        color: var(--av-nav-primary);
    }
    .order-product-remove {
        margin-inline-start: 4px;
        .a-glyph {
            width: 32px;
            height: 32px;
            padding: 8px;
            display: block;
            cursor: pointer;
            margin-top: -4px;
            border-radius: 4px;
            fill: var(--av-brand-primary);
            &:hover {
                background-color: var(--av-brand-secondary-accent);
            }
            &:active {
                background-color: var(--av-brand-secondary-light);
            }
        }
    }
    .order-product-quantity {
        color: var(--av-fixed-secondary);
    }
    .order-product-period {
        color: var(--av-fixed-secondary);
    }
    .total {
        display: flex;
        padding: 16px 0 0;
        align-items: flex-start;
        justify-content: space-between;
        border-top: 1px solid var(--av-brand-secondary-light);
        .total-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &:first-child {
                gap: 8px;
            }
        }
        .total-text {
            @mixin title;
            color: var(--av-nav-primary);
        }
        .total-vat {
            @mixin paragraph;
            color: var(--av-fixed-secondary);
        }
        .total-full {
            @mixin paragraph;
            position: relative;
            color: var(--av-fixed-light);
            &:before {
                inset-inline-start: 0;
                top: 58%;
                height: 1px;
                content: "";
                width: 100%;
                position: absolute;
                background: var(--av-fixed-light);
            }
        }
        .total-current {
            @mixin display-accent;
        }
    }
    &:deep(.a-button) {
        width: 100%;
        margin: 16px 0 0;
        user-select: none;
        justify-content: center;
        .a-button__glyph {
            margin-inline-start: 8px;
        }
    }
}
.list {
    margin-top: 24px;
    .list-item {
        text-align: center;
        @media (--viewport-tablet) {
            text-align: start;
        }
        &:deep(.a-link) {
            &.a-link_accent {
                font-weight: 700;
            }
            .a-link__content {
                .a-glyph {
                    display: none;
                    @media (--viewport-tablet) {
                        display: inline;
                    }
                }
            }
        }
    }
}
</style>
