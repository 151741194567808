<template>
    <section class="express-signup-trial-success" :class="basicClassName">
        <div class="a-container">
            <transition :name="transitionName" :appear="isFirstLoad" mode="out-in">
                <component
                    :is="activeComponent"
                    v-if="!isAnimation"
                    :question="activeQuestion"
                    :success="success"
                    @answeredQuestion="onNextStep($event)"
                />
            </transition>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';
import QuestionsStep from './steps/QuestionsStep.vue';
import SuccessStep from './steps/SuccessStep.vue';

export default {
    name: 'SExpressSignupTrialSuccess',
    components: {
        questions: QuestionsStep,
        finish: SuccessStep,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        background: {
            type: String,
            default: 'light-blue',
        },
        questions: {
            type: Array,
            default: () => [],
        },
        success: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            isAnimation: true,
            questionId: 1,
            activeComponent: 'questions',
            isFirstLoad: true,
            transitionName: 'express-signup-slide-up',
        };
    },
    computed: {
        activeQuestion() {
            return this.questions.find((elem) => elem.question_id === this.questionId);
        },
        hashes() {
            return ['finish', ...this.questions.map((q) => q.hash)];
        },
    },
    watch: {
        '$route.hash': {
            handler(to) {
                this.isAnimation = true;
                this.loadPage(to);
                return true;
            },
        },
    },
    mounted() {
        this.isFirstLoad = false;

        if (!this.activeQuestion) {
            this.goToFinish();
            return;
        }

        if (!this.$route) return;

        const appropriateHash = this.hashes.includes(this.$route.hash) ? this.$route.hash : this.activeQuestion.hash;
        this.$router?.push({ hash: appropriateHash });
        this.loadPage(appropriateHash);
    },
    updated() {
        this.isAnimation = false;
    },
    methods: {
        ...mapActions({
            doExpressSignupRequest: 'expressSignup/doExpressSignupRequest',
        }),
        goToFinish() {
            this.$router?.push({ hash: '#finish' });
            this.activeComponent = 'finish';
        },
        loadPage(to) {
            const clearedHash = to.replace('#', '');
            const question = this.questions.find((elem) => elem.hash === clearedHash);
            this.activeComponent = 'questions';
            if (!question) return this.onSuccess();
            this.questionId = question.question_id;
            this.isAnimation = false;
            return false;
        },
        async sendDataToMarketo() {
            const secondsOnPage = formHelper.getSecondsOnPage();

            const params = {
                form_id: 'cloud_trial_survey_form',
                challenge: localStorage.getItem('trial_es_challenge'),
                role_in_sp: localStorage.getItem('trial_es_role'),
                email: localStorage.getItem('trial_es_email'),
                osauid: localStorage.getItem('trial_es_osauid'),
                munchkinId: formHelper.getCookies(formHelper.MRKTO_COOKIE_NAME) || formHelper.createMarketoToken(),
                google_analytics_trackingid: 'UA-149943-47',
                google_analytics_userid: formHelper.getCookies(formHelper.UID_COOKIE_NAME) || '',
                google_analytics_clientid: formHelper.getCIDval() || '[ERROR] Client ID not generated',
                ...formHelper.labelsUTM(),
                // WEB-44946
                uit: secondsOnPage,
            };

            await this.doExpressSignupRequest(params);

            localStorage.setItem('express-signup-submitted', '1');

            formHelper.sendDataLayer({
                eventCategory: 'form',
                eventAction: 'submit_form_success',
                eventLabel: 'cloud_trial_survey_form',
                formTimer: secondsOnPage, // WEB-44946
                emailAddress: await commonUtils.toSHA256String(params.email), // WEB-45304
            });
        },
        storeAnswer(answer) {
            const key = answer.questionId === 1 ? 'challenge' : 'role';
            localStorage.setItem(`trial_es_${key}`, answer.text);
            this.sendAnswerToGA(answer.text);
        },
        sendAnswerToGA(answer) {
            formHelper.sendDataLayer({
                eventCategory: 'survey',
                eventLabel: answer,
                eventAction: `question_${this.questionId}`,
            });
        },
        onNextStep(answer) {
            this.isAnimation = true;
            this.transitionName = 'express-signup-slide-left';
            this.storeAnswer(answer);

            this.questionId++;
            if (this.questionId > this.questions.length) {
                this.questionId--;
                return this.onSuccess(true);
            }

            return this.$router?.push({ hash: `#${this.activeQuestion.hash}` });
        },
        onSuccess(sendToMarketo = false) {
            this.isAnimation = true;
            this.transitionName = 'express-signup-slide-up';
            this.goToFinish();
            if (!this.$router) {
                this.isAnimation = false;
                return;
            }
            if (sendToMarketo && !localStorage.getItem('express-signup-submitted')) {
                this.sendDataToMarketo();
            }
            this.isAnimation = false;
        },
    },
};
</script>

<style lang="postcss" scoped>
section.express-signup-trial-success {
    position: relative;
    overflow-x: hidden;
    min-height: 480px;
}

.express-signup-slide-up-leave-active,
.express-signup-slide-up-enter-active {
    transition: 0.5s;
}

.express-signup-slide-up-enter {
    transform: translateY(50%);
    opacity: 0;
}

.express-signup-slide-up-leave-to {
    transform: translateY(0);
    opacity: 1;
}

.express-signup-slide-left-leave-active,
.express-signup-slide-left-enter-active {
    transition: all 0.3s;
}

.express-signup-slide-left-enter {
    transform: translateX(80%);
    opacity: 0;
}

.express-signup-slide-left-leave-to {
    transform: translateX(0);
    opacity: 1;
}
</style>
