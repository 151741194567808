<template>
    <section class="header-lp-three-products">
        <a-picture
            v-if="background"
            :background="background"
            fit="cover"
            is-background
        />
        <div class="a-container">
            <dynamic-link class="header-lp-three-products__logo-link" :to="logoLink">
                <a-logo class="header-lp-three-products__logo" type="full" fill="white" />
            </dynamic-link>
            <div class="header-lp-three-products__header">
                <h1 class="header-lp-three-products__title">
                    {{ title }}
                </h1>
                <div class="header-lp-three-products__info">
                    <div class="header-lp-three-products__info-content">
                        <div v-for="(item, i) in footer" :key="i" class="header-lp-three-products__info-item">
                            <div class="header-lp-three-products__info-title">
                                {{ item.title }}
                            </div>
                            <div class="header-lp-three-products__info-description">
                                {{ item.description }}
                            </div>
                        </div>
                    </div>
                    <a-picture
                        v-if="logo"
                        class="header-lp-three-products__info-image"
                        :link="logo"
                        exact
                    />
                </div>
            </div>
            <div class="header-lp-three-products__container">
                <div v-for="(product, i) in cards" :key="i" class="header-lp-three-products__item">
                    <div class="product-card">
                        <div v-if="isDesktop" class="product-card__image-wrap">
                            <a-label
                                v-if="product.labels"
                                class="product-card__label"
                                v-bind="product.labels[0]"
                                :theme="product.labels[0].theme || 'dark'"
                                :variant="product.labels[0].variant || 'success'"
                            >
                                {{ product.labels[0].text }}
                            </a-label>
                            <a-label
                                v-else
                                class="product-card__type"
                                theme="dark"
                            >
                                {{ product.type }}
                            </a-label>
                            <a-picture class="product-card__image" :link="product.image" />
                        </div>
                        <div class="product-card__content">
                            <div v-if="product.labels" class="product-card__labels">
                                <a-label
                                    v-for="(label, labelIndex) in product.labels.slice(isDesktop)"
                                    :key="`label-${labelIndex}`"
                                    v-bind="label"
                                    :variant="label.variant || 'success'"
                                >
                                    {{ label.text }}
                                </a-label>
                            </div>
                            <a-label
                                v-else
                                class="product-card__type"
                                theme="dark"
                            >
                                {{ product.type }}
                            </a-label>
                            <div class="product-card__title">
                                {{ product.name }}
                            </div>
                            <div class="product-card__formerly">
                                {{ product.formerly }}
                            </div>
                            <div class="product-card__text">
                                {{ product.text }}
                            </div>
                            <a-product-cta
                                :settings="{ hasLabel: true, hasDescription: true, ...product.productPageCTA }"
                                use-new-label-design
                            />
                            <div class="product-card__offer" :class="{'full-width': isSingleLineCTAButtons}">
                                <a-button
                                    v-for="(button, j) in product.buttons"
                                    :key="j"
                                    class="product-card__button"
                                    v-bind="button"
                                    :class="{'full-width': isSingleLineCTAButtons}"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALabel from '@core/components/label/label.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import purchasing from '@core/mixins/purchasing.js';

export default {
    name: 'SHeaderLpThreeProducts',
    components: {
        DynamicLink,
        ALogo,
        APicture,
        AButton,
        ALabel,
        AProductCta,
    },
    mixins: [breakpoint, purchasing, analytics, cleverbridge],
    props: {
        /**
         * Ability to change the link of the logo
         */
        logoLink: {
            type: String,
            default: '/',
            required: false,
        },
        isSingleLineCTAButtons: {
            type: Boolean,
            default: false,
        },
        cards: {
            type: Array,
            required: true,
        },
        background: {
            type: Object,
            required: true,
        },
        footer: {
            type: Array,
            required: true,
        },
        logo: {
            type: String,
            required: false,
            default: undefined,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            EVENT_TIMEOUT: 10 * 1000,
        };
    },
    mounted() {
        setTimeout(() => {
            this.sendDataLayer({
                eventCategory: 'Timing',
                eventContext: 'timing',
                eventAction: this.$route.path,
                eventLabel: '10sec',
            });
        }, this.EVENT_TIMEOUT);
    },
    methods: {
        sendDataLayer(data) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'Acronis',
                ...data,
            });
        },
    },
    async serverPrefetch() {
        await this.initSettingsCTA(this.cards);
    },
};
</script>

<style lang="postcss" scoped>
.header-lp-three-products {
    @mixin basic-slice-paddings;
    position: relative;
    width: 100%;
    background: linear-gradient(
        134.26deg,
        #193a83 1.43%,
        #143578 20.95%,
        #0e2f6d 40.47%,
        #082a63 59.98%,
        #042558 79.5%,
        #00204e 99.02%
    );

    &__header {
        margin-bottom: 48px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        @media (--viewport-tablet) {
            flex-wrap: nowrap;
        }
    }

    &__info {
        display: flex;
        padding-inline-start: 0;
        position: relative;
        @media (--viewport-tablet) {
            padding-inline-start: 24px;
        }

        &::after {
            content: '';
            position: absolute;
            inset-inline-start: 0;
            bottom: 0;
            top: 0;
            width: 1px;
            background: #9fb7d6;
            mix-blend-mode: normal;
            opacity: 0.5;
            display: none;
            @media (--viewport-tablet) {
                display: block;
            }
        }

        &-image {
            max-width: 64px;
            max-height: 64px;
            min-height: 64px;
            min-width: 64px;
            margin-inline-start: 24px;
            margin-top: 8px;
            @media (--viewport-tablet) {
                margin-inline-start: 42px;
            }
        }

        &-item {
            display: flex;
            margin-top: -3px;
        }

        &-title {
            @mixin body;
            font-weight: 700;
            color: var(--av-inversed-primary);
        }

        &-description {
            @mixin body;
            color: #b3c8e4;
            margin-inline-start: 8px;
        }

        &:deep(img) {
            width: 100%;
        }
    }

    &__logo {
        margin-bottom: 48px;
        width: 118.49px;
        height: 24.06px;
        @media (--viewport-tablet) {
            margin-bottom: 40px;
        }
        @media (--viewport-desktop) {
            width: 158px;
            height: 32px;
        }
    }

    &__title {
        @mixin display;
        margin-bottom: 40px;

        color: var(--av-inversed-primary);

        @media (--viewport-tablet) {
            @mixin colls 6;
            margin-bottom: 0;
        }
        @media (--viewport-desktop) {
            @mixin colls 7;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        @media (--viewport-tablet) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &__item {
        width: 100%;
        margin-bottom: 16px;
        @media (--viewport-tablet) {
            @mixin colls 4;
            margin-bottom: 0;
        }
    }
}

.product-card {
    box-shadow: 0 0 1px rgba(0, 32, 77, 0.1763), 0 2px 6px rgba(0, 32, 77, 0.1);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid var(--av-nav-secondary);

    &__image-wrap {
        display: block;
        position: relative;
        overflow: hidden;
        min-height: 168px;
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        inset-inline-start: 0;
        top: 0;

        &:deep(.a-picture__img) {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__type {
        position: relative;
        margin-bottom: 16px;
        width: fit-content;

        @media (--viewport-desktop) {
            position: absolute;
            inset-inline-start: 24px;
            top: 24px;
            margin-bottom: 0;
        }
    }

    &__labels {
        display: flex;
        margin-bottom: 16px;

        .a-label {
            &:not(:last-child) {
                margin-inline-end: 8px;
            }
        }

        @media (--viewport-tablet) {
            display: block;

            .a-label {
                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }

        @media (--viewport-desktop) {
            margin: 0;

            .a-label {
                &:not(:last-child) {
                    margin-top: 16px;
                }
            }
        }
    }

    &__label {
        top: 24px;
        position: absolute;
        inset-inline-start: 24px;
    }

    &__content {
        padding: 24px;
        color: var(--av-nav-primary);
        background: var(--av-inversed-primary);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__title {
        @mixin title-accent;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            min-height: 64px;
        }
        @media (--viewport-desktop) {
            min-height: auto;
        }
        @media (--viewport-desktop-wide) {
            min-height: 64px;
        }
    }

    &__formerly {
        margin-bottom: 8px;
        @mixin body-accent;
        color: var(--av-nav-primary);

        @media (--viewport-desktop) {
            @mixin lead-accent;
        }
    }

    &__text {
        @mixin body;
        margin-bottom: 24px;
        color: var(--av-fixed-light);

        @media (--viewport-tablet) {
            max-width: 100%;
        }

        @media (--viewport-desktop) {
            @mixin paragraph;
            margin-bottom: 24px;
        }
    }

    &__offer {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: auto;

        &.full-width {
            flex-wrap: wrap;
        }
        @media (--viewport-desktop) {
            flex-wrap: nowrap;
        }
    }

    &__button {
        @mixin colls 12;
        display: flex;
        align-items: center;
        padding: 0 16px;
        margin-top: 16px;
        height: 48px;

        &.full-width {
            @mixin colls 12;
            margin-top: 16px;
        }

        &:first-child {
            margin-top: 0;
        }

        @media (--viewport-desktop) {
            @mixin colls 6;
            height: 40px;
            margin-top: 0;

            &.full-width:not(:first-child) {
                margin-top: 16px;
            }
        }
    }

    &:deep(.cta) {
        margin: auto 0 24px;

        ~ .product-card__offer {
            flex-direction: row-reverse;
            margin-top: unset;
        }
    }
}
</style>
