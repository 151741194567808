<template>
    <div class="s-slice s-product-demo-modal" :class="sliceClass">
        <a-picture
            v-if="background"
            class="background"
            :background="background"
            is-background
            fit="cover"
        />
        <div class="container a-container">
            <div class="main">
                <a-label v-if="label" :type="labelType" :variant="labelVariant">
                    {{ label }}
                </a-label>
                <h2 v-if="title" class="title">
                    {{ title }}
                </h2>
                <a-dangerous-html v-if="lead" class="lead" :content="lead" />
                <a-dangerous-html v-if="description" class="description" :content="description" />
                <ul v-if="bulletListItems.length" class="bullets-list">
                    <li v-for="item in bulletListItems" :key="item" class="bullets-item">
                        <a-dangerous-html class="bullets-item-text" :content="item" />
                    </li>
                </ul>
                <div
                    v-if="!isCtaOverMedia"
                    ref="ctaBlock"
                    v-modal="id"
                    @click="onModalOpen"
                    @close="stopTracking"
                >
                    <a-button
                        v-if="cta"
                        ref="ctaButton"
                        class="cta-button"
                        size="m"
                        v-bind="ctaSettings"
                    />
                </div>
            </div>
            <div class="media" :class="{'with-cta': isCtaOverMedia}">
                <div class="screenshot-wrapper">
                    <a-picture
                        :is-shadow="false"
                        v-bind="productImage"
                        class="screenshot-image"
                    />
                </div>
                <div
                    v-if="isCtaOverMedia"
                    ref="ctaBlock"
                    v-modal="id"
                    class="cta-block"
                    @click="onModalOpen"
                    @close="stopTracking"
                >
                    <a-button
                        v-if="cta"
                        ref="ctaButton"
                        class="cta-button"
                        v-bind="ctaSettings"
                    />
                </div>
            </div>
            <a-modal :id="id" type="modal" :class="{ 'fullscreen': fitToFullscreen }">
                <div v-if="!isMobile" class="modal-contents" :style="modalStyle">
                    <div
                        v-if="!iframeLoaded"
                        v-loading="true"
                        class="loader"
                        el-loading-size="48"
                        el-loading-color="brand-secondary"
                    />
                    <iframe
                        ref="iframe"
                        :src="figmaSrc"
                        allowfullscreen
                        class="iframe"
                    />
                </div>
                <div v-show="isMobile" class="modal-mobile">
                    <div class="text">
                        {{ mobileModalText }}
                    </div>
                    <a-button
                        data-close
                        class="mobile-close-button"
                        size="m"
                        v-bind="closeButtonSettings"
                    />
                </div>
            </a-modal>
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALabel from '@core/components/label/label.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SProductDemoModal',

    components: {
        AModal,
        AButton,
        ADangerousHtml,
        ALabel,
        APicture,
    },

    directives: {
        Modal,
        Loading,
    },

    mixins: [breakpoint],

    props: {
        /**
         * Figma Source
         */
        figmaSrc: {
            type: String,
            required: true,
        },
        /**
         * Label
         */
        label: {
            type: String,
            default: undefined,
        },
        labelVariant: {
            type: String,
            required: false,
            default: 'info',
        },
        labelType: {
            type: String,
            default: 'label',
        },
        /**
         * Title
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Lead
         */
        lead: {
            type: String,
            default: undefined,
        },
        /**
         * Description
         */
        description: {
            type: String,
            default: undefined,
        },
        /**
         * Background image
         */
        background: {
            type: Object,
            default: null,
        },
        /**
         * Bullet list items
         */
        bulletListItems: {
            type: Array,
            default: () => [],
        },
        /**
         * Image thumbnail for modal
         */
        productImage: {
            type: Object,
            required: true,
        },
        /**
         * Aspect ratio for modal contents (ignored if fitToFullscreen is true)
         */
        modalAspectRatio: {
            type: Number,
            default: 16 / 9,
        },
        /**
         * Is CTA button placed over media
         */
        isCtaOverMedia: {
            type: Boolean,
            default: true,
        },
        /**
         * CTA button settings
         */
        cta: {
            type: Object,
            default: null,
        },
        /**
         * GA events
         */
        openEvent: {
            type: Object,
            default: () => ({}),
        },
        closeEvent: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Make the popup take as mush space as possible
         */
        fitToFullscreen: {
            type: Boolean,
            default: true,
        },
        /**
         * Text for modal on mobile
         */
        mobileModalText: {
            type: String,
            required: true,
        },
        /**
         * Close button settings
         */
        mobileCloseButton: {
            type: Object,
            default: () => ({}),
        },
        /**
         * Use left aligment
         */
        useLeftAlignment: {
            type: Boolean,
            default: true,
        },
        /**
         * Use gradient background (if used without image)
         */
        useGradientBackground: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        id: 'figma-demo',
        iframeLoaded: false,
        startTime: null,
    }),

    computed: {
        sliceClass() {
            return {
                'align-left': this.useLeftAlignment,
                'gradient-background': this.useGradientBackground,
            };
        },

        modalStyle() {
            if (this.fitToFullscreen) return {};
            return { 'aspect-ratio': this.modalAspectRatio };
        },

        ctaSettings() {
            const fallback = {
                type: 'main',
                size: 's',
                glyph: 'i-chevron-big-right--16',
            };
            return { ...fallback, ...this.cta };
        },

        closeButtonSettings() {
            const fallback = {
                type: 'inverse',
                size: 's',
                text: 'Close',
            };
            return { ...fallback, ...this.mobileCloseButton };
        },
    },

    watch: {
        isMobile(isMobile) {
            if (!isMobile) this.onModalOpen();
        },
    },

    beforeDestroy() {
        this.stopTracking();
    },

    methods: {
        async onModalOpen() {
            if (this.isMobile) return;

            await this.$nextTick();

            if (this.iframeLoaded) {
                this.initTracking();
                return;
            }

            this.$refs.iframe.onload = () => {
                this.iframeLoaded = true;
                this.initTracking();
            };
        },

        initTracking() {
            if (this.startTime) return;

            this.startTime = new Date();
            this.sendDataLayerEvent(this.openEvent);
        },

        stopTracking() {
            const endTime = new Date();
            const timeOnIframe = endTime - this.startTime;
            const seconds = timeOnIframe / 1000;

            const timeOnDemoText = (s) => {
                if (s < 10) return '0–10sec';
                if (s < 30) return '11–30sec';
                if (s < 60) return '31–60sec';
                if (s < 180) return '1-3mins';
                if (s < 300) return '3-5mins';
                if (s < 600) return '5-10mins';
                if (s < 900) return '10-15mins';
                if (s < 1200) return '15-20mins';
                if (s < 1800) return '20-30mins';
                return '30+ minutes';
            };

            this.sendDataLayerEvent({
                ...this.closeEvent,
                eventContext: timeOnDemoText(seconds),
            });
            this.startTime = null;
        },

        sendDataLayerEvent(event) {
            const e = {
                event: 'Acronis',
                eventCategory: 'Interactions',
                ...event,
            };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(e);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-product-demo-modal {
    position: relative;
    background: var(--av-solid-brand-lightest);
}

.s-product-demo-modal.gradient-background {
    background: linear-gradient(180deg, rgba(248, 252, 255, 1) 0%, rgba(233, 240, 249, 1) 100%);
}
.s-product-demo-modal.align-left {
    .title {
        @mixin display-accent;
    }

    .container {
        @media (--viewport-desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .main {
        text-align: left;

        .cta-button {
            @media (--viewport-desktop) {
                margin-top: 16px;
            }
        }
    }

    .screenshot-wrapper {
        @media (--viewport-tablet) {
            @mixin colls 8;
            margin-inline: auto;
        }

        @media (--viewport-desktop) {
            width: 100%;
        }
    }
}

.s-product-demo-modal:not(.align-left) {
    .container {
        max-width: 1090px;
    }
}

.background {
    position: absolute;
    top: 0;
    inset-inline: 0;
    width: 100%;
    height: 100%;
}

.container {
    padding-block: 64px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.main {
    text-align: center;
    @media (--viewport-desktop) {
        padding-inline-end: 32px;
        margin-bottom: 0;
    }

    .cta-button {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 24px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }
}

.media {
    position: relative;
    display: block;

    @media (--viewport-desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    &.with-cta {
        cursor: pointer;

        &:hover {
            .screenshot-wrapper {
                box-shadow: none;
            }

            .screenshot-image {
                opacity: 0.6;
            }

            .a-picture {
                transform: scale(1.02);
            }

            .cta-button {
                box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
            }
        }
    }
}

.a-label {
    margin-bottom: 4px;
}

.title {
    @mixin display-accent;
    color: var(--av-nav-primary);
    margin-bottom: 24px;

    @media (--viewport-mobile-wide) {
        @mixin hero-accent;
    }
}

.lead {
    @mixin lead;
    color: var(--av-fixed-secondary);
    margin-bottom: 24px;

    @media (--viewport-mobile-wide) {
        @mixin title;
    }
}

.description {
    @mixin paragraph;
    color: var(--av-fixed-secondary);
    margin-bottom: 24px;
}

.bullets-list {
    list-style: disc;
    list-style-position: inside;
    margin-bottom: 24px;
}

.bullets-item-text {
    @mixin paragraph;
    color: var(--av-fixed-secondary);
    display: inline;
}

.screenshot-image.a-picture {
    border-radius: 4px;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    will-change: opacity, transform;
    overflow: hidden;
}

.cta-block {
    position: absolute;
    inset: 0;
    display: flex;
    user-select: none;
    align-items: center;
    justify-content: center;
    background: radial-gradient(50.09% 77.73% at 50.09% 50%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);

    &:deep(.a-button__content) {
        user-select: none;
    }
}

.cta-button {
    transition: box-shadow ease-in-out 0.2s;
}

.screenshot-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background: var(--av-solid-brand-lightest);
    border: 1px solid var(--av-brand-secondary-light, rgba(64, 139, 234, 0.3));
    box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
    transition: box-shadow .2s ease-in-out;
}

.a-picture {
    transition: transform 0.2s ease-in-out;
    will-change: transform;
}

.a-modal.type_modal:deep(.content),
.a-modal.type_modal:deep(.inner) {
    display: flex;
    align-items: center;
    height: auto;
    max-width: 1440px;
    @media (max-width: 1023px) {
        width: 100%;
    }
}

.a-modal.fullscreen.type_modal:deep(.content),
.a-modal.fullscreen.type_modal:deep(.inner) {
    height: 100%;
    width: 100%;
    max-width: none;
}

.a-modal.fullscreen.type_modal:deep(.content) {
    @media (--viewport-desktop) {
        width: calc(100% - 64px);
    }
}

.a-modal.fullscreen.type_modal:deep(.modal-contents) {
    height: 100%;
}

.loader {
    z-index: -1;
    top: 50%;
    margin-top: -24px;
    height: 48px;
}

.iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

.modal-contents {
    height: auto;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.overlay {
    pointer-events: none;
    width: 100%;
    height: 100%;

    &.active {
        pointer-events: all;
    }
}

.modal-mobile {
    text-align: center;
    color: var(--av-inversed-primary);

    .text {
        @mixin title-accent;
        margin-bottom: 24px;
    }
}

.mobile-close-button {
    border-color: var(--av-brand-secondary);
}
</style>
