<template>
    <div class="blog-page blog-home">
        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />
        <div class="main-content">
            <s-main-header v-bind="sliceDataHeader" />
            <blog-container class="intro">
                <blog-head :title="translations.homeTitle" v-bind="sectionLink" />
                <blog-dropdown-menu
                    v-if="isMobile"
                    :links="[...menuLinks, browseAllStoriesLink]"
                    :placeholder="translations.selectCategory"
                />
                <blog-featured-post class="featured-post" :post="topPost">
                    <template v-if="!isMobile" #menu>
                        <blog-side-menu :links="menuLinks" :title="translations.menuDefaultTitle" :extra-link="browseAllStoriesLink" />
                    </template>
                </blog-featured-post>
                <blog-tabs v-if="introTabs.length" :tabs="introTabs" />
            </blog-container>
            <blog-container v-if="localeHasCPC" class="insights" theme="dark-navy">
                <blog-insights
                    :title="translations.insightsTitle"
                    :lead="translations.insightsLead"
                    :link="{ title: translations.insightsLink, to: '/cyber-protection-center/' }"
                    :spotlighted-card="spotlightedCard"
                    :news-title="translations.newsTitle"
                    :news="CPCNews"
                    :news-video-label="translations.videoLabel"
                    :cards="featuredCPCCards"
                />
            </blog-container>
            <blog-container class="latest-cards">
                <blog-cards
                    :title="translations.homeCardsTitle"
                    :follow-us-on="translations.followUsOn"
                    :social-links="socialLinks"
                    :cards="latestCards"
                    :is-loading="isXHRInProgress"
                >
                    <!-- <template #sidebar>
                        <blog-twitter-sidebar :feed-address="translations.twitterFeedAddress" :ui-language="languageCode" />
                    </template> -->
                    <template v-if="listTotalPages > 1" #pagination>
                        <blog-pager
                            class="pagination"
                            :current-page="listCurrentPage"
                            :total-pages="listTotalPages"
                            :base-url="baseUrl"
                            @pageSelected="loadLatestCardsPage"
                        />
                    </template>
                </blog-cards>
            </blog-container>
            <blog-container class="subscribe" theme="light-grey" width="short">
                <blog-subscribe
                    view="page-view"
                    :title="translations.subscribeFormTitle"
                    :lead="translations.subscribeFormLead"
                    :button="translations.subscribe"
                    :email="translations.emailAddress"
                    :agreement="translations.subscribeAgreement"
                    :sample-text="translations.mailSampleText"
                    :success-title="translations.subscribeSuccessTitle"
                    :success-text="translations.subscribeSuccessText"
                    :back-to-form-title="translations.tryAgainTitle"
                    :articles="latestCards"
                />
            </blog-container>
        </div>
        <s-global-footer class="footer" v-bind="sliceDataFooter" />
    </div>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import breakpoint from '@core/mixins/breakpoint.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import BlogCards from '@core/slices/blog/blog-cards/blog-cards.vue';
import BlogDropdownMenu from '@core/slices/blog/blog-dropdown-menu/blog-dropdown-menu.vue';
import BlogFeaturedPost from '@core/slices/blog/blog-featured-post/blog-featured-post.vue';
import BlogHead from '@core/slices/blog/blog-head/blog-head.vue';
import BlogInsights from '@core/slices/blog/blog-insights/blog-insights.vue';
import BlogSubscribe from '@core/slices/blog/blog-subscribe/blog-subscribe.vue';
import BlogTabs from '@core/slices/blog/blog-tabs/blog-tabs.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';
import BlogPager from '@core/slices/blog/shared-components/blog-pager/blog-pager.vue';
import BlogSideMenu from '@core/slices/blog/shared-components/blog-side-menu/blog-side-menu.vue';
// import BlogTwitterSidebar from '@core/slices/blog/shared-components/blog-twitter-sidebar/blog-twitter-sidebar.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import { SECTION_ID_CPC, SECTION_ID_BLOG, SECTION_SLUG_BY_ID } from '@model/const/blog.ts';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/blog.pcss';

export default {
    name: 'BlogBlogHome',

    components: {
        SMainHeader,
        SGlobalFooter,
        BlogHead,
        BlogDropdownMenu,
        BlogCards,
        BlogFeaturedPost,
        BlogSideMenu,
        BlogTabs,
        BlogInsights,
        BlogContainer,
        BlogSubscribe,
        BlogPager,
        // BlogTwitterSidebar,
        SLocaleSelectorRibbon,
    },

    mixins: [blog, analytics, breakpoint, localeRibbon],

    props: {
        dispatchLoadLatestCardsPage: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            socials,
            isXHRInProgress: false,
            listCurrentPage: 1,
        };
    },

    computed: {
        // ==== Input params
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        baseUrl() {
            const sectionSlug = SECTION_SLUG_BY_ID[SECTION_ID_BLOG];
            return `/${this.locale}/${sectionSlug}/`;
        },

        localeHasCPC() {
            return !!this.$store.state.locales.locale?.is_active_on_blog_cpc;
        },

        sectionLink() {
            if (!this.localeHasCPC) {
                return null;
            }

            return {
                sectionLink: `/${SECTION_SLUG_BY_ID[SECTION_ID_CPC]}/`,
                sectionTitle: this.translations.cpcTitle,
                sectionText: this.translations.cpcLead,
            };
        },

        languageCode() {
            return this.locale.split('-').filter(Boolean)[0];
        },

        browseAllStoriesLink() {
            return {
                title: this.translations.browseAllStories,
                link: `/${this.locale}/blog/search/`,
                glyph: 'search',
            };
        },

        // ==== Slices data
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'light',
            };
        },

        sliceDataFooter() {
            return {
                ...this.$store.state.slices.items['s-global-footer'],
                type: 'short',
                theme: 'light',
            };
        },

        // ==== Store mappers
        categories() {
            return this.$store.state.blog.categories.items || [];
        },

        menuLinks() {
            return this.categories.map((category) => this.getCategoryFields(category, this.locale));
        },

        topPost() {
            const post = (this.$store.state.blog.topPost.items || [])[0];
            return this.getBlogPostFields(post, this.translations);
        },

        latestCards() {
            const cards = this.$store.state.blog.latestCards.items || [];
            return cards.map((card) => this.getBlogPostFields(card, this.translations));
        },

        listTotalPages() {
            return this.$store.state.blog.latestCards.pagination?.pages_total || 1;
        },

        introTabs() {
            const getBlogPostFieldsCurried = (card) => this.getBlogPostFields(card, this.translations);
            const featuredCards = (this.$store.state.blog.featuredBlog.items || []).map(getBlogPostFieldsCurried);
            const popularCards = (this.$store.state.blog.popularBlog.items || []).map(getBlogPostFieldsCurried);

            const mapTab = (label, cards) => (cards.length ? { label, cards } : null);
            return [
                mapTab(this.translations.editorPicksTitle, featuredCards),
                mapTab(this.translations.popular, popularCards),
            ].filter(Boolean);
        },

        spotlightedPost() {
            const post = (this.$store.state.blog.spotlightedCPC.items || [])[0];
            return this.getBlogPostFields(post, this.translations);
        },

        // if there are no spotlighted items we are using first featured instead
        spotlightedCard() {
            if (this.spotlightedPost) {
                return this.spotlightedPost;
            }

            const post = (this.$store.state.blog.featuredCPC.items || [])[0];
            return this.getBlogPostFields(post, this.translations);
        },

        featuredCPCCards() {
            const featured = (this.$store.state.blog.featuredCPC.items || [])
                .map((card) => this.getBlogPostFields(card, this.translations));

            return this.spotlightedPost
                ? featured.slice(0, -1)
                : featured.slice(1);
        },

        CPCNews() {
            const news = this.$store.state.blog.CPCNews.items || [];
            return news.map((item) => this.getNewsFields(item));
        },

        socialLinks() {
            const linksSource = this.translations.customSocialLinks || socials.links;
            const links = Array.from(linksSource);
            const rss = {
                link: '/blog/feed.xml#',
                name: 'rss',
                title: 'RSS',
            };
            links.push(rss);
            return links;
        },
    },

    mounted() {
        this.listCurrentPage = parseInt(this.$route.params.page, 10) || 1;
    },

    methods: {
        async loadLatestCardsPage(page) {
            this.isXHRInProgress = true;
            const success = await this.dispatchLoadLatestCardsPage(page);
            this.isXHRInProgress = false;
            if (success) {
                this.listCurrentPage = page;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-home {
    .intro {
        padding-bottom: 80px;
    }

    .featured-post {
        padding-bottom: 40px;

        @media (--viewport-tablet) {
            padding-bottom: 80px;
        }

        @media (--viewport-desktop) {
            padding-bottom: 32px;
        }
    }

    .latest-cards {
        padding-top: 64px;
        padding-bottom: 88px;
    }

    .pagination {
        margin: 80px auto 0;
    }

    .subscribe {
        padding: 64px 0 40px;
        border-bottom: 1px solid var(--av-brand-light);
    }
}
</style>
