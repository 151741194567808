<template>
    <div class="s-job-description">
        <s-main-section v-if="mainSection" v-bind="mainSection" />
        <div
            v-if="isLoading"
            v-loading="true"
            class="loader"
            el-loading-size="48"
        />
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import { mapState } from 'vuex';
import SMainSection from './components/main-section.vue';

export default {
    name: 'SJobDescription',

    directives: { Loading },

    components: {
        SMainSection,
    },

    props: {
        /**
         * Breadcrumbs
         */
        breadcrumbs: {
            type: Array,
            default: () => [],
        },
        /**
         * Autofill button
         */
        autofillButton: {
            type: Object,
            default: null,
        },
        /**
         * Apply button
         */
        applyButton: {
            type: Object,
            default: null,
        },
        /**
         * Use last button
         */
        useLastButton: {
            type: Object,
            default: null,
        },
        /**
         * Date title with plural days
         */
        postedDateTitle: {
            type: String,
            default: 'Posted $date Days Ago',
        },
        /**
         * Date title with singular day
         */
        postedDateSingleTitle: {
            type: String,
            default: 'Posted $date Day Ago',
        },
        /**
         * Redirect link when job not found
         */
        notFoundRedirectLink: {
            type: String,
            default: '/careers/jobs-new/',
        },
        /**
         * Note for application
         */
        applicationNote: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            job: null,
            isLoading: true,
        };
    },
    computed: {
        ...mapState({
            jobPostings: (state) => state.workday?.items || [],
        }),

        mainSection() {
            if (!this.job) return null;

            const primaryLocation = this.job.primaryLocation.descriptor;
            const additionalLocations = this.job.additionalLocations?.map((x) => x.descriptor) || [];

            const autofillButton = {
                to: `${this.job.url}${this.autofillButton.urlPart}`,
                ...this.autofillButton,
            };
            const applyButton = {
                to: `${this.job.url}${this.applyButton.urlPart}`,
                ...this.applyButton,
            };
            const useLastButton = {
                to: `${this.job.url}${this.useLastButton.urlPart}`,
                ...this.useLastButton,
            };

            const jobId = this.job.url?.split('/').pop().split('_').pop() || '';

            return {
                breadcrumbs: this.breadcrumbs,
                autofillButton,
                applyButton,
                useLastButton,
                department: this.job.categories?.[0]?.descriptor,
                jobId,
                title: this.job.title,
                date: this.job.startDate,
                shortDescription: this.job.shortDescription,
                fullDescription: this.job.jobDescription,
                primaryLocation,
                additionalLocations,
                timeType: this.job.timeType?.descriptor,
                postedDateTitle: this.postedDateTitle,
                postedDateSingleTitle: this.postedDateSingleTitle,
                applicationNote: this.applicationNote,
            };
        },
    },
    async mounted() {
        // fix for storybook
        if (!this.$router) {
            this.job = this.jobPostings[0];
            this.isLoading = false;
            return;
        }

        await this.authorize();
        await this.tryCatchJobPostings();

        const slug = this.$route.query?.slug;
        this.job = this.jobPostings.find((x) => x.url?.split('/').pop().split('_').pop() === slug);
        if (!this.job || !slug) {
            window.location.href = `${this.notFoundRedirectLink}?error=404&slug=${slug}`;
        }
    },
    methods: {
        async authorize() {
            await fetch('/public/integrations/workday/authorize');
        },

        async fetchJobPostings() {
            const response = await fetch('/public/integrations/workday/jobPostings');
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }
            const jobs = await response.json();
            this.$store.dispatch('workday/setJobs', jobs);
            this.isLoading = false;
        },

        async tryCatchJobPostings() {
            try {
                await this.fetchJobPostings();
            } catch {
                try {
                    await this.authorize();
                    await this.fetchJobPostings();
                } catch {
                    this.isLoading = false;
                }
            }
        },
    },
};
</script>
<style lang="postcss" scoped>
.loader {
    margin-top: 128px;
}
</style>
