<template>
    <div class="intro">
        <div class="menu-links-wrap">
            <div v-sticky="stickyOptions" class="menu-links-sticky">
                <div class="menu-links a-container">
                    <div
                        v-for="link in menuLinks"
                        :key="link.title"
                    >
                        <a-link
                            v-if="link.to"
                            class="menu-link"
                            v-bind="link"
                            :text="link.title || link.text"
                        />
                        <template v-else>
                            {{ link.title }}
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="a-container">
            <div class="main-content">
                <div class="title-wrap">
                    <a-dangerous-html tag="h1" :content="title" class="title" />
                    <p v-if="lead" class="lead">
                        {{ lead }}
                    </p>
                    <div>
                        <a-button
                            v-if="exploreButton"
                            v-bind="exploreButton"
                            class="explore-jobs-button"
                            :size="exploreButton.size || 'm'"
                            :type="exploreButton.type || 'main'"
                        />
                    </div>
                </div>
                <div>
                    <div v-modal="video.ytId" class="video-wrap">
                        <a-picture
                            class="video-thumb"
                            :link="video.thumb"
                            fit="cover"
                            loading="lazy"
                        />
                        <button class="video-play" type="button" aria-label="Open video">
                            <a-glyph class="play-icon" name="play-gradient" />
                        </button>
                    </div>
                </div>
                <a-modal
                    :id="video.ytId"
                    type="youtube"
                    is-youtube
                >
                    <a-youtube :yt-id="video.ytId" :params="video.ytParams || { autoplay: 1 }" />
                </a-modal>
            </div>
            <div class="main-links">
                <dynamic-link
                    v-for="link in mainLinks"
                    :key="link.title"
                    v-bind="link"
                    :text="link.title || link.text"
                    class="link"
                >
                    <div class="link-title">
                        {{ link.title }}
                    </div>
                    <div class="link-text">
                        {{ link.text }}
                    </div>
                </dynamic-link>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import Modal from '@core/directives/modal.js';
import Sticky from '@core/directives/sticky.js';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';

export default {
    name: 'SCareersIntro',

    components: {
        AYoutube,
        AModal,
        ALink,
        AGlyph,
        APicture,
        DynamicLink,
        AButton,
        ADangerousHtml,
    },

    directives: {
        Modal,
        Sticky,
    },

    mixins: [form, breakpoint],

    props: {
        menuLinks: {
            type: Array,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        lead: {
            type: String,
            default: null,
        },

        video: {
            type: Object,
            required: true,
        },

        mainLinks: {
            type: Array,
            required: true,
        },

        exploreButton: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            stickyOptions: { parent: '.menu-links-wrap' },
        };
    },
};
</script>

<style lang="postcss" scoped>
.intro {
    position: relative;
    padding: 120px 0 80px;

    @media (--viewport-tablet) {
        padding-top: 128px;
    }

    @media (--viewport-desktop) {
        padding-top: 96px;
    }

    .menu-links-wrap {
        border-top: 1px solid var(--av-brand-secondary-light);
        position: relative;
        top: 0;
        width: 100%;
        height: 56px;
        display: none;

        @media (--viewport-desktop) {
            display: block;
        }

        &:deep(.a-link__content) {
            @mixin paragraph;
        }
    }

    .menu-links-sticky {
        width: 100%;
        height: 56px;
        padding-block: 16px;
        background: var(--av-inversed-primary);
        z-index: 2;

        &.is-sticky {
            position: fixed;
            top: 0;
            box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
        }
    }

    .menu-links {
        display: flex;
        justify-content: flex-start;
        column-gap: 48px;
        color: var(--av-nav-primary);
    }

    .title-wrap,
    .el-select,
    .video-wrap,
    .main-links {
        max-width: 584px;
        margin-inline: auto;

        @media (--viewport-desktop) {
            max-width: none;
        }
    }

    .main-content {
        @media (--viewport-desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 16px;
            padding-top: 48px;
            margin-bottom: 64px;
        }
    }

    .title-wrap {
        text-align: center;

        @media (--viewport-desktop) {
            text-align: start;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-inline-end: 16px;
        }
    }

    .title {
        font-weight: 800;
        font-size: 48px;
        line-height: 48px;
        color: var(--av-nav-primary);
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            font-size: 64px;
            line-height: 64px;
        }

        @media (--viewport-desktop) {
            font-size: 80px;
            line-height: 80px;
        }

        @media (--viewport-desktop-wide) {
            font-size: 96px;
            line-height: 88px;
        }
    }

    .lead {
        @mixin paragraph;

        color: var(--av-nav-primary);
        margin-bottom: 24px;

        @media (--viewport-desktop) {
            margin-bottom: 40px;
        }
    }

    .el-select {
        text-align: start;
        margin-bottom: 48px;

        &:deep(.el-input__container) {
            background: var(--av-brand-lightest);
        }

        &:deep(.el-input__placeholder) {
            @mixin paragraph;

            color: var(--av-fixed-primary);
        }

        &:deep(.el-input:hover .el-input__container) {
            border-color: var(--av-brand-primary);
        }

        &:deep(.el-input__editor ){
            @mixin paragraph;
        }

        @media (--viewport-desktop) {
            width: 75%;
            margin-bottom: 0;
            margin-inline-start: 0;
        }
    }

    .video-wrap {
        position: relative;
        margin-bottom: 48px;
        width: 100%;
        aspect-ratio: 327/169;
        cursor: pointer;

        @media (--viewport-tablet) {
            font-size: 64px;
            line-height: 64px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }

        &:hover {
            .play-icon {
                opacity: 0.8;
            }
        }
    }

    .video-thumb {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;

        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
        }
    }

    .video-play {
        position: absolute;
        border: 0;
        outline: 0;
        border-radius: 50%;
        background: var(--av-inversed-primary);
        width: 56px;
        height: 56px;
        inset: 0;
        margin: auto;
        text-align: center;
        cursor: pointer;

        @media (--viewport-tablet) {
            width: 76px;
            height: 76px;
        }
    }

    .play-icon {
        height: 24px;
        width: 24px;
        position: relative;
        inset-inline-start: 3px;

        @media (--viewport-tablet) {
            width: 32px;
            height: 32px;
        }
    }

    .main-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        @media (--viewport-desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .link {
        padding: 24px 24px 32px 24px;
        background: var(--av-brand-lightest);
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-secondary-light);
        border-radius: 12px;

        @media (--viewport-desktop) {
            height: 100%;
        }

        &:hover {
            box-shadow: none;

            .link-title {
                color: var(--av-nav-secondary);
            }
        }
    }

    .link-title {
        @mixin title-accent;

        color: var(--av-brand-primary);
        margin-bottom: 8px;

        @media (--viewport-desktop) {
            @mixin display-accent;
        }
    }

    .link-text {
        @mixin paragraph;

        color: var(--av-nav-primary);
    }

    .explore-jobs-button {
        @mixin title-accent;

        padding: 8px 32px 10px;
        border-radius: 12px;
        align-items: baseline;
        margin-bottom: 40px;
        background: linear-gradient(270deg, rgba(31, 191, 242, 1), rgba(0, 148, 255, 1), rgba(0, 74, 167, 1)), rgba(31, 191, 242, 1);

        &:deep(.a-button__glyph) {
            margin-inline-start: 10px;
        }

        &:hover {
            background: linear-gradient(270deg, rgba(8, 196, 255, 1), rgba(16, 120, 217, 1), rgba(0, 59, 133, 1)), rgba(8, 196, 255, 1);

            &:deep(.a-glyph) {
                transform: translateX(4px);
            }
        }

        &:active {
            background: linear-gradient(270deg, rgba(0, 175, 230, 1), rgba(0, 100, 192, 1), rgba(0, 59, 133, 1)), rgba(0, 74, 167, 1);
        }
    }
}
</style>

<style lang="postcss">
.careers-dropdown.el-select-dropdown {
    .el-input__editor,
    .el-input__placeholder,
    .el-select-dropdown__item {
        @mixin paragraph;
    }

    .el-input__wrapper {
        padding-inline: 5px;
    }

    .el-select-group__wrap {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        padding: 4px 16px;
        cursor: pointer;

        &::after {
            display: none;
        }

        &:hover {
            background: rgba(230, 237, 251, 1);
        }
    }

    .el-select-group__title {
        @mixin body;

        color: var(--av-nav-secondary);
        height: 24px;
        padding: 0;

        + li {
            width: 100%;
            overflow: hidden;
        }
    }

    .el-autocomplete {
        padding: 0 11px;
    }

    .el-select-dropdown__item {
        height: 32px;
    }

    .el-input__editor,
    .el-input__placeholder,
    .el-select-dropdown__item {
        @mixin paragraph;
    }

    .el-select-dropdown__item {
        padding: 0;

        &.hover,
        &:not(.selected):hover {
            background: none;
        }
    }
}
</style>
